import { Row, Col, Tag, Button, Divider } from "antd";
import React from "react";

const TopBar = (props) => {
  return (
    <div className="subheader">
      <Row className="container">
        <Col span={8}>
          <Row>
            <p className="page-header">Profile</p>
          </Row>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={16}>
          {props.editable ? (
            <React.Fragment>
              <Button onClick={() => props.onChangeEdit(false)} type="default">
                Cancel
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                className="inline-form-item"
                type="primary"
                onClick={() => props.onChangePassword(true)}
              >
                Change Password
              </Button>
              <Button
                onClick={() => props.onChangeEdit(true)}
                className="inline-form-item"
                type="default"
              >
                Edit
              </Button>
            </React.Fragment>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TopBar;
