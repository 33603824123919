import React, { Component, FunctionComponent } from "react";
import { Col, Row, Skeleton } from "antd";
import { Pie } from "@ant-design/charts";

import Filters from "./partials/Filters";
import TotalContent from "./partials/TotalContent";
import TopPartners from "./partials/TopPartners";
import TopUsers from "./partials/TopUsers";

import { DataCard } from "../../components/DataCard";

// Types
import { MembershipSummary, TopBrandsSummary, UserSummary } from "./types";

// Data
import AvocadoAdmin from "../../api/avocado.admin";
import { UserContext } from "../../context/userContext";
import { dashboardFilterToParams, processDashboardFilters } from "./common";
import { useHistory } from "react-router";
const MEMBERSHIP_COLORS = ["gold", "silver", "#cd7f32", "black"];

interface DashboardProps {
  match: {
    path: "/admin" | "/partner";
  };
  history: useHistory;
  location: Location;
}

const AdminAdditionalMetrics: FunctionComponent<{
  membershipSummary?: MembershipSummary;
  brandSummary?: TopBrandsSummary;
  userSummary?: UserSummary;
}> = ({ membershipSummary, brandSummary, userSummary }) => {
  if (!membershipSummary || !brandSummary || !userSummary) return null;

  let avgMembershipsPerPartner =
    membershipSummary.totalMemberships / brandSummary.totalBrands;
  let avgMembershipsPerUAU =
    membershipSummary.totalMemberships / userSummary.activeUsers;
  let activeUserPercentage =
    (userSummary.activeUsers / userSummary.totalUsers) * 100;

  let billableUserPerPartner =
    membershipSummary.billableMembers / brandSummary.totalBrands;

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <TopPartners brands={brandSummary} />
        </Col>
        <Col span={6}>
          <DataCard
            title="Total Partners"
            value={`${brandSummary.totalBrands}`}
          />
          <DataCard
            title="Avg. Memberships Per Partner"
            value={avgMembershipsPerPartner.toFixed(2)}
          />
          <DataCard
            title="Billable Memberships per partner"
            value={`${billableUserPerPartner.toFixed(2)}`}
          />
        </Col>
        <Col span={6}>
          <DataCard title="Total Users" value={`${userSummary.totalUsers}`} />
          <DataCard
            title="Avg. Memberships Per U.A.U"
            value={avgMembershipsPerUAU.toFixed(2)}
          />
        </Col>
        <Col span={6}>
          <DataCard
            title="Unique Active Users"
            value={`${userSummary.activeUsers}`}
          />
          <DataCard
            title="Active User Percentage"
            value={`${activeUserPercentage.toFixed(2)}%`}
          />
        </Col>
      </Row>
    </>
  );
};

const PartnerAdditionalMetrics: FunctionComponent<{
  membershipSummary?: MembershipSummary;
  brandSummary?: TopBrandsSummary;
  userSummary?: UserSummary;
}> = ({ membershipSummary, brandSummary, userSummary }) => {
  if (!membershipSummary || !brandSummary || !userSummary) return null;

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col span={6}>
          <TopPartners brands={brandSummary} />
        </Col>
        <Col span={12}>
          <TopUsers userSummary={userSummary} />
        </Col>
        <Col span={6}>
          <DataCard title="Total Users" value={`${userSummary.totalUsers}`} />
          <DataCard
            title="Total Partners"
            value={`${brandSummary.totalBrands}`}
          />
        </Col>
      </Row>
    </>
  );
};

export default class Dashboard extends Component<DashboardProps, any> {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      dashboardMetrics: {},
      user: {},
    };

    this.isAdmin = this.isAdmin.bind(this);

    this.getAdminDashboardData = this.getAdminDashboardData.bind(this);
    this.getPartnerDashboardData = this.getPartnerDashboardData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.setUser = this.setUser.bind(this);
  }

  componentDidMount() {
    window.document.title = "Avocado - Dashboard";
    this.setUser();
    const filters = processDashboardFilters(
      new URLSearchParams(this.props.location.search)
    );
    console.log(filters);
    if (this.props.match && this.props.match.path === "/admin") {
      this.getAdminDashboardData(filters.filter);
    } else {
      this.getPartnerDashboardData(filters.filter);
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      const filters = processDashboardFilters(
        new URLSearchParams(this.props.location.search)
      );
      console.log(filters);
      if (this.props.match && this.props.match.path === "/admin") {
        this.getAdminDashboardData(filters.filter);
      } else {
        this.getPartnerDashboardData(filters.filter);
      }
    }
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin";
  }

  setUser() {
    const [userState, setUser] = this.context;
    this.setState({ user: userState });
  }

  async getAdminDashboardData(filters = null) {
    this.setState({ isLoading: true });
    try {
      let result = await AvocadoAdmin.getDashboardDataAll(filters);
      this.setState({ dashboardMetrics: result.body });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async getPartnerDashboardData(filters = null) {
    this.setState({ isLoading: true });
    const [userState, setUser] = this.context;

    console.log(userState);
    try {
      let result = await AvocadoAdmin.getDashboardInsightsById(
        filters,
        userState.permissions.scope
      );

      this.setState({ dashboardMetrics: result.body });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async onSubmit(filters) {
    const params = {};

    if (filters.partner && filters.partner !== "ALL")
      params["brandId"] = filters.partner;
    if (filters.startDate) params["startDate"] = filters.startDate;
    if (filters.endDate) params["endDate"] = filters.endDate;
    let data = dashboardFilterToParams(params);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
  }

  getColor(entry) {
    let color;
    switch (entry) {
      case "Gold":
        color = "gold";
        break;
      case "Silver":
        color = "silver";
        break;
      case "Bronze":
        color = "#CD7F32";
        break;
      default:
        color = "black";
        break;
    }
    return color;
  }

  render() {
    let isAdmin = false;
    if (this.props.match && this.props.match.path === "/admin") isAdmin = true;
    const filters = processDashboardFilters(
      new URLSearchParams(this.props.location.search)
    );
    const {
      brands,
      content,
      memberships,
      users,
      mini,
      dailyBreakdown,
    } = this.state.dashboardMetrics;

    let piechartConfig = {
      height: 200,
      autoFit: true,
      appendPadding: [0, 0, 12, 0],
      data: memberships
        ? memberships.membershipLevelDetails.map((m) => ({
            count: m.count,
            membershipLevel: `${m.membershipLevel
              .toLowerCase()[0]
              .toUpperCase()}${m.membershipLevel
              .toLowerCase()
              .slice(1, m.membershipLevel.toLowerCase().length)}`,
          }))
        : [],
      angleField: "count",
      colorField: "membershipLevel",
      radius: 1,
      innerRadius: 0.64,
      color: (record) => this.getColor(record.membershipLevel),
      statistic: {
        title: false as false,
        content: {
          style: {
            whiteSpace: "pre-wrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "none",
          },
          formatter: function formatter() {
            return "AntV\nG2Plot";
          },
        },
      },
      legend: {
        layout: "horizontal" as "horizontal",
        position: "bottom" as "bottom",
      },
      label: {
        type: "inner",
        offset: "-50%",
        style: { textAlign: "center" },
        autoRotate: false,
        content: "{value}",
      },
    };

    return (
      <React.Fragment>
        <div className="filter">
          <Filters
            filters={filters}
            onSubmit={this.onSubmit}
            showPartners={isAdmin}
          />
        </div>
        <div className="container">
          {this.state.dashboardMetrics && this.state.isLoading === false ? (
            <React.Fragment>
              <Row gutter={[20, 20]}>
                <Col span={18}>
                  <TotalContent
                    dailyBreakdown={dailyBreakdown}
                    users={users}
                    content={content}
                  ></TotalContent>
                </Col>
                <Col span={6}>
                  <DataCard
                    title="Pending Approvals"
                    value={content && content.pending ? content.pending : "-"}
                  />
                  <DataCard
                    title="Billable  Memberships"
                    value={
                      memberships && memberships.billableMembers
                        ? memberships.billableMembers
                        : "-"
                    }
                  />
                  <DataCard
                    title={`Total Memberships`}
                    value={
                      memberships && memberships.totalMemberships
                        ? memberships.totalMemberships
                        : "-"
                    }
                  >
                    <div style={{ flex: 1 }}>
                      <Pie {...piechartConfig} />
                    </div>
                  </DataCard>
                </Col>
              </Row>
              {!mini && isAdmin ? (
                <AdminAdditionalMetrics
                  membershipSummary={memberships}
                  brandSummary={brands}
                  userSummary={users}
                />
              ) : null}

              {!mini && !isAdmin ? (
                <PartnerAdditionalMetrics
                  membershipSummary={memberships}
                  brandSummary={brands}
                  userSummary={users}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <Skeleton active></Skeleton>
          )}
        </div>
      </React.Fragment>
    );
  }
}
