import { message, Skeleton } from "antd";
import React, { Component } from "react";
import AvocadoAdmin from "../../../api/avocado.admin";
import PartnerDetails from "../../../components/partner-details";
import PartnerDetailsForm from "../../../components/partner-details-form";
import ChangePassword from "./partials/ChangePassword";
import TopBar from "./partials/TopBar";

export interface PartnerProfileProps {
  match: {
    path: "/admin/partners";
  };
}

export interface PartnerProfileState {
  partner: {};
  isLoading: boolean;
  isEditable: boolean;
  isChangePassword: boolean;
}

export default class PartnerProfile extends Component<
  PartnerProfileProps,
  PartnerProfileState
> {
  constructor(props: PartnerProfileProps) {
    super(props);
    this.state = {
      partner: null,
      isLoading: false,
      isEditable: false,
      isChangePassword: false,
    };

    this.getPartner = this.getPartner.bind(this);
    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setChangePassword = this.setChangePassword.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
  }

  componentDidMount() {
    window.document.title = "Avocado - Profile";

    this.getPartner();
  }

  async getPartner() {
    const user = JSON.parse(localStorage.getItem("avocado-user"));
    this.setState({ isLoading: true });
    try {
      let result = await AvocadoAdmin.getBrandById(
        null,
        user.permissions.scope
      );
      this.setState({ partner: result.body });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  onChangeEdit(value) {
    this.setState({ isEditable: value });
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin/partners";
  }

  async onSubmit(values) {
    this.setState({ isLoading: true, isEditable: false });
    console.log(values);
    const user = JSON.parse(localStorage.getItem("avocado-user"));

    try {
      let result = await AvocadoAdmin.updateBrand(
        values,
        user.permissions.scope
      );
      this.setState({ partner: result.body });
    } catch (error) {
      console.log(error);
    }
    this.setState({ isLoading: false });
  }

  setChangePassword(value) {
    this.setState({ isChangePassword: value });
  }

  async onChangePassword(values) {
    try {
      let result = await AvocadoAdmin.changePassword(values);
      message.success("Password Changed");
    } catch (error) {
      message.error("Oops. Something Went Wrong");
    }
    this.setChangePassword(false);
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="container" style={{ marginTop: "1.6rem" }}>
            <Skeleton active></Skeleton>
          </div>
        ) : (
          <React.Fragment>
            <TopBar
              editable={this.state.isEditable}
              onChangeEdit={this.onChangeEdit}
              onChangePassword={this.setChangePassword}
            />
            {this.state.partner ? (
              <div className="container">
                {this.state.isEditable ? (
                  <PartnerDetailsForm
                    isAdmin={this.isAdmin()}
                    partner={this.state.partner}
                    onSubmit={this.onSubmit}
                  ></PartnerDetailsForm>
                ) : (
                  <PartnerDetails
                    isAdmin={this.isAdmin()}
                    partner={this.state.partner}
                  ></PartnerDetails>
                )}
              </div>
            ) : null}
          </React.Fragment>
        )}
        <ChangePassword
          isVisible={this.state.isChangePassword}
          setChangePassword={this.setChangePassword}
          onChangePassword={this.onChangePassword}
        ></ChangePassword>
      </React.Fragment>
    );
  }
}
