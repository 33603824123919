export const benefitsToTable = (data) => {
  let tempObj = {
    gold: extractItems(data, "GOLD", "cardType"),
    silver: extractItems(data, "SILVER", "cardType"),
    bronze: extractItems(data, "BRONZE", "cardType"),
  };
  return tempObj;
};

const extractItems = (props, key, param) => {
  const item = props.filter((data) =>
    data[`${param}`] === key ? data : false
  );
  let data = item[0];
  console.log(data);
  return data;
};

export const mapBenifitsToArray = (data) => {
  return Object.values(data);
};

export const linksToForm = (data) => {
  let tempObj = {
    facebook: extractLinks(data, "facebook", "type"),
    instagram: extractLinks(data, "instagram", "type"),
    website: extractLinks(data, "website", "type"),
  };
  return tempObj;
};

const extractLinks = (props, key, param) => {
  const item = props.filter((data) => {
    if (data) {
      return data[`${param}`] === key ? data : false;
    }
  });
  let data = item[0];
  return data;
};
