import React, { useEffect, useState, useLayoutEffect } from "react";

import { Card, Avatar, Row, Col, Typography } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  getTwoToneColor,
  SettingOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { Meta } = Card;
const { Paragraph } = Typography;

const CampaignCard = (props) => {

  const campaign = props.campaign;
  const history = useHistory();
  useLayoutEffect(() => {

  }, []);
  const isAdmin = () => {
   
    return history.location.pathname === `/admin/campaigns`;
  };
  const navigate = () => {
    if (campaign.status === "rejected" && !isAdmin()) {
      history.push(`campaigns/edit/${campaign._id}`);
    } else {
      history.push(`campaigns/view/${campaign._id}`);
    }
  };
  const getColor = () => {
    let color = "#EBBB33";
    switch (campaign.status) {
      case "active":
        color = "#5CAD46";
        break;
      case "approved":
        color = "#5CAD46";
        break;
      case "pending":
        color = "#EBBB33";
        break;
      case "completed":
        color = "#CECECE";
        break;
      case "rejected":
        color = "#FF4141";
        break;
      default:
        color = "#EBBB33";
        break;
    }
    return color;
  };
  return (
    <React.Fragment>
      <Card
        style={{
          padding: 0,
          marginBottom: 10,
          height: "186px",
        }}
        onClick={() => {
          navigate();
        }}
      >
        <Row>
          <Col span={6}>
            <img
              style={{
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
                width: "269px",
                height: "186px",
              }}
              alt="example"
              src={campaign.coverPhoto}
            />
          </Col>
          <Col span={18}>
            <div style={{ padding: 20, height:106 }}>
              <div
                style={{
                  fontSize: 26,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {campaign.title}
                  <span
                    style={{
                      marginLeft: 20,
                      color: getColor(),
                      fontSize: 12,
                      border: "2px solid",
                      padding: "5px 10px",
                      borderRadius: 5,
                    }}
                  >
                    {campaign.status}
                  </span>
                </div>
                <div
                  style={{ fontSize: 14, fontWeight: "normal", paddingTop: 10 }}
                >
                  {new Date(campaign.startDate).toDateString()} -{" "}
                  {new Date(campaign.endDate).toDateString()}
                </div>
              </div>
              <div style={{ fontSize: 14 }}>
                Campaign Type :{" "}
                <span
                  style={{
                    color: "#5CAD46",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                  }}
                >
                  {campaign.type}
                </span>
              </div>
            </div>
            <div style={{ borderTop: "#DEDEDE solid 1px", padding: 15, height:80 }}>
              {campaign.status == "Active" ||
              campaign.status == "active" ||
              campaign.status == "completed" ||
              campaign.status == "Completed" ? (
                <Row style={{     padding: "0px 15px"}}>
                  <Col
                    sapn={6}
                    style={{
                      borderRight: "#DEDEDE solid 1px",
                      paddingRight: "15px",
                    }}
                  >
                    <p style={{ fontSize: 14, marginBottom:0 }}>Total Content Generated</p>
                    <p style={{ fontSize: 18, fontWeight: "bold" }}>
                      {campaign.contentStats.totalContent}
                    </p>
                  </Col>
                  <Col
                    sapn={6}
                    style={{
                      height:50,
                      borderRight: "#DEDEDE solid 1px",
                      paddingLeft: "15px",
                      paddingRight: "15px"
                 
                    }}
                  >
                    <p style={{ fontSize: 14, marginBottom:0 }}>Total Users</p>
                    <p style={{ fontSize: 18, fontWeight: "bold" }}>
                      {campaign.contentStats.totalUsers}
                    </p>
                  </Col>
                  <Col
                    sapn={6}
                    style={{
                      borderRight: "#DEDEDE solid 1px",
                      paddingLeft: "15px",
                      paddingRight: "15px"
                      // padding: "0px 15px",
                    }}
                  >
                    <p style={{ fontSize: 14, marginBottom:0 }}>Average Rating</p>
                    <p style={{ fontSize: 18, fontWeight: "bold" }}>
                      {campaign.contentStats.averageRating}
                    </p>
                  </Col>
                  <Col sapn={6} style={{ paddingLeft: "15px" }}>
                    <p style={{ fontSize: 14, marginBottom:0 }}>Rejection Rate</p>
                    <p style={{ fontSize: 18, fontWeight: "bold" }}>
                      {campaign.contentStats.rejectedContent}
                    </p>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Paragraph ellipsis={{ rows: 2 }}>
                    {campaign.description}
                  </Paragraph>
                </Row>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default CampaignCard;
