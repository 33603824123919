import React, { useContext } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import SignIn from "./pages/Auth/sign-in/sign-in";

import Admin from "./pages/Admin/Admin";
import Partner from "./pages/Partner/Partner";
import { UserContext, UserProvider } from "./context/userContext";
import { useHistory } from "react-router";
import PrivateRoute from "./components/PrivateRoute";
import Moderator from "./pages/Moderator/Moderator";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import { PartnerProvider } from "./context/partnerContext";

function App() {
  return (
    <Router>
      <Switch>
        <UserProvider>
          <PartnerProvider>
            <Route exact path="/" component={SignIn}></Route>
            <Route
              exact
              path="/forgot-password"
              component={ForgotPassword}
            ></Route>
            <Route path="/reset-password" component={ResetPassword}></Route>
            <PrivateRoute path="/admin" component={Admin}></PrivateRoute>
            <PrivateRoute path="/partner" component={Partner}></PrivateRoute>
            <PrivateRoute
              path="/moderator"
              component={Moderator}
            ></PrivateRoute>
          </PartnerProvider>
        </UserProvider>
      </Switch>
    </Router>
  );
}

export default App;
