import { Row, Col, Form, Input } from "antd";
import { Formik } from "formik";
import React from "react";
import StarRatings from "react-star-ratings";

const AdminPointsStars = (props) => {
  const { editable,stars } = props;

  return (
    <div style={{ height: "384px" }} className="rounded card">
      <Row align="middle" justify="space-between" className="rating-container">
        <Formik
          initialValues={null}
          onSubmit={(values, actions) => {
            console.log({ values, actions });
            alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);
          }}
        >
          <Col style={{ height: "384px" }} span={24}>
            <Form style={{ height: "384px",display:'flex',flexDirection:'column',justifyContent:'space-between' }} >
              <Row className="points-rating-row" justify="space-between">
                <Col span={16}>
                  <StarRatings
                    rating={1}
                    starRatedColor="gold"
                    numberOfStars={5}
                    name="rating"
                    starDimension="2rem"
                  />
                </Col>
                <Col span={6}>
                  {editable ? (
                    <Input className="rounded points-input" value={1}></Input>
                  ) : (
                    <p
                      style={{ textAlign: "right" }}
                      className="star-count-value"
                    >
                      {stars[0].points}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="points-rating-row" justify="space-between">
                <Col span={16}>
                  <StarRatings
                    rating={2}
                    starRatedColor="gold"
                    numberOfStars={5}
                    name="rating"
                    starDimension="2rem"
                  />
                </Col>
                <Col className="points-rating-row" span={6}>
                  {editable ? (
                    <Input className="rounded points-input" value={3}></Input>
                  ) : (
                    <p
                      style={{ textAlign: "right" }}
                      className="star-count-value"
                    >
                      {stars[1].points}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="points-rating-row" justify="space-between">
                <Col span={16}>
                  <StarRatings
                    rating={3}
                    starRatedColor="gold"
                    numberOfStars={5}
                    name="rating"
                    starDimension="2rem"
                  />
                </Col>
                <Col className="points-rating-row" span={6}>
                  {editable ? (
                    <Input className="rounded points-input" value={7}></Input>
                  ) : (
                    <p
                      style={{ textAlign: "right" }}
                      className="star-count-value"
                    >
                     {stars[2].points}
                    </p>
                  )}
                </Col>
              </Row>

              <Row className="points-rating-row" justify="space-between">
                <Col span={16}>
                  <StarRatings
                    rating={4}
                    starRatedColor="gold"
                    numberOfStars={5}
                    name="rating"
                    starDimension="2rem"
                  />
                </Col>
                <Col span={6}>
                  {editable ? (
                    <Input className="rounded points-input" value={15}></Input>
                  ) : (
                    <p
                      style={{ textAlign: "right" }}
                      className="star-count-value"
                    >
                      {stars[3].points}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="points-rating-row" justify="space-between">
                <Col span={16}>
                  <StarRatings
                    rating={5}
                    starRatedColor="gold"
                    numberOfStars={5}
                    name="rating"
                    starDimension="2rem"
                  />
                </Col>
                <Col span={6}>
                  {editable ? (
                    <Input className="rounded points-input" value={30}></Input>
                  ) : (
                    <p
                      style={{ textAlign: "right" }}
                      className="star-count-value"
                    >
                    {stars[4].points}
                    </p>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Formik>
      </Row>
    </div>
  );
};

export default AdminPointsStars;
