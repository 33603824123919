import {
  Button,
  Col,
  Rate,
  Row,
  Skeleton,
  Tag,
  message,
  Modal,
  Collapse,
} from "antd";
import React, { Component } from "react";
import StarRatings from "react-star-ratings";
import AvocadoModerator from "../../../api/avocado.moderator";
import Lottie from "react-lottie";
import ReactPlayer from "react-player";
import Plyr from "plyr";

import nothingFound from "../../../assets/nothingFound.json";
import ReactJson from "react-json-view";

export interface ContentRatingProps {}

export interface ContentRatingState {
  content: any[];
  isLoading: boolean;
  selectedContent: any;
  isMetaShow: boolean;
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: nothingFound,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
class ContentRating extends React.Component<
  ContentRatingProps,
  ContentRatingState
> {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      isLoading: false,
      selectedContent: null,
      isMetaShow: false,
    };
    this.getContent = this.getContent.bind(this);
    this.onRating = this.onRating.bind(this);
  }

  componentDidMount() {
    this.getContent();
  }

  async getContent() {
    this.setState({ isLoading: true });
    try {
      let result = await AvocadoModerator.getContentBucket();
      this.setState({
        content: result.body,
        selectedContent: result.body.length > 0 ? result.body[0] : null,
      });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async rateContent(status) {
    console.log("this was called", this.state.selectedContent.status);
    this.setState({ isLoading: true });
    const { _id, rating } = this.state.selectedContent;
    const payload = {
      status: status,
      rating: status ? rating : undefined,
      contentId: _id,
    };

    try {
      let result = await AvocadoModerator.approveContent(payload);
      let contentId = result.body.contentId;
      let tempArr = this.state.content.filter((item: any) => {
        if (item._id === contentId) {
          return false;
        } else return true;
      });
      console.log(tempArr);
      if (tempArr.length === 0) {
        this.getContent();
      } else {
        this.setState({ content: tempArr, selectedContent: tempArr[0] });
      }
    } catch (error) {
      console.log(error);
    }

    this.setState({ isLoading: false });
  }

  onRating(value) {
    console.log(value);
    const tempObj = { ...this.state.selectedContent, rating: value };
    console.log("rated obj", tempObj);
    this.setState({ selectedContent: tempObj });
  }

  render() {
    const { Panel } = Collapse;

    return (
      <React.Fragment>
        <Row justify="space-between">
          {this.state.isLoading ? (
            <Skeleton className="container" active></Skeleton>
          ) : (
            <Col span={24}>
              {this.state.selectedContent ? (
                <React.Fragment>
                  <div>
                    <div className="subheader">
                      <Row className="container">
                        <h6>{this.state.content.length} Remaining</h6>
                      </Row>
                    </div>
                  </div>
                  <div className="container">
                    <Row gutter={[20, 20]}>
                      <Col span={16}>
                        <Row
                          style={{
                            backgroundColor:
                              this.state.selectedContent.contentType == "image"
                                ? "black"
                                : "none",
                            borderRadius: "20px",
                          }}
                        >
                          {this.state.selectedContent.contentType ===
                          "image" ? (
                            <img
                              style={{
                                borderRadius: "20px",
                                maxWidth: "810px",
                                maxHeight: "580px",
                                objectFit: "contain",
                              }}
                              className="moderator-img"
                              height={"auto"}
                              width={"100%"}
                              src={this.state.selectedContent.contentURL}
                            ></img>
                          ) : (
                            <video
                              style={{
                                maxWidth: "810px",
                                maxHeight: "580px",
                                objectFit: "contain",
                                backgroundColor: "black",
                              }}
                              height={"auto"}
                              width={"100%"}
                              src={this.state.selectedContent.contentURL}
                              controls={true}
                            ></video>
                          )}
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row className="rounded card image-details">
                          {this.state.selectedContent ? (
                            <Col span={24}>
                              <Row>
                                <Col span={8}>
                                  <label>Partner</label>
                                </Col>
                                <Col span={16}>
                                  {this.state.selectedContent.brand ? (
                                    <span>
                                      {this.state.selectedContent.brand.name}
                                    </span>
                                  ) : (
                                    "-"
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <label>Tags</label>
                                </Col>
                                <Col span={16}>
                                  {this.state.selectedContent.tags.map(
                                    (tag) => (
                                      <Tag>{tag}</Tag>
                                    )
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <label>Caption</label>
                                </Col>
                                <Col span={16}>
                                  <span>
                                    {this.state.selectedContent.caption
                                      ? this.state.selectedContent.caption
                                      : "-"}
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <label>Uploaded By</label>
                                </Col>
                                <Col span={16}>
                                  {this.state.selectedContent.member ? (
                                    <span>
                                      {
                                        this.state.selectedContent.member
                                          .firstName
                                      }
                                      &nbsp;
                                      {
                                        this.state.selectedContent.member
                                          .lastName
                                      }
                                    </span>
                                  ) : (
                                    <span>Avocado User</span>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={8}>
                                  <label>Moderation Count</label>
                                </Col>
                                <Col span={16}>
                                  {this.state.selectedContent
                                    .moderationActivity ? (
                                    <span>
                                      {
                                        this.state.selectedContent
                                          .moderationActivity.length
                                      }
                                      {this.state.selectedContent
                                        .moderationActivity.length === 1 ? (
                                        <>&nbsp; Pass</>
                                      ) : (
                                        <>&nbsp; Passes</>
                                      )}
                                    </span>
                                  ) : (
                                    <span>-</span>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          ) : null}
                        </Row>
                        {/* {this.state.selectedContent.meta ? (
                          <a
                            className="meta-data"
                            onClick={() => this.setState({ isMetaShow: true })}
                          >
                            Show Additional Data
                          </a>
                        ) : null} */}

                        <Row align="middle" justify="space-between">
                          <Col span={12}>
                            <Row className='moderator-rate'>
                              <Rate
                                style={{ fontSize: "28px" }}
                                value={
                                  this.state.selectedContent.rating
                                    ? this.state.selectedContent.rating
                                    : null
                                }
                                onChange={(e) => {
                                  this.onRating(e);
                                }}
                              />
                            </Row>
                          </Col>
                          <Col span={12}>
                            <Row justify="end">
                              <Button
                                onClick={() => this.rateContent(1)}
                                type="primary"
                                disabled={
                                  this.state.selectedContent.rating
                                    ? false
                                    : true
                                }
                              >
                                Rate
                              </Button>
                              <Button
                                style={{
                                  background: "#FF4141",
                                  marginLeft: "1rem",
                                }}
                                onClick={() => this.rateContent(0)}
                              >
                                Reject
                              </Button>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="subheader">
                    <Row justify="center" className="container">
                      <h6>
                        Your moderation queue is empty at the moment. Please
                        check again in a few minutes
                      </h6>
                    </Row>
                  </div>
                  <div>
                    <Lottie options={defaultOptions} height={400} width={400} />
                  </div>
                </React.Fragment>
              )}
            </Col>
          )}
        </Row>
        {this.state.selectedContent && this.state.selectedContent.meta ? (
          <Row style={{ marginBottom: "1rem" }} className="container">
            <Col span={24}>
              <Collapse>
                <Panel header="Meta Data" key="1">
                  {this.state.selectedContent &&
                  this.state.selectedContent.meta ? (
                    // <div>{JSON.stringify(this.state.selectedContent.meta).replace(",","\n")}</div>
                    <ReactJson
                      style={{ fontSize: "1rem" }}
                      displayDataTypes={false}
                      name={false}
                      src={this.state.selectedContent.meta}
                    />
                  ) : null}
                </Panel>
              </Collapse>
            </Col>
          </Row>
        ) : null}

        <Modal
          title="Meta Data"
          visible={this.state.isMetaShow}
          onCancel={() => this.setState({ isMetaShow: false })}
          footer={
            <Button
              type="primary"
              onClick={() => this.setState({ isMetaShow: false })}
            >
              Close
            </Button>
          }
        >
          {this.state.selectedContent && this.state.selectedContent.meta ? (
            // <div>{JSON.stringify(this.state.selectedContent.meta).replace(",","\n")}</div>
            <ReactJson
              style={{ fontSize: "1rem" }}
              displayDataTypes={false}
              name={false}
              src={this.state.selectedContent.meta}
            />
          ) : null}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ContentRating;
