import { Row, Col, Divider, Input, Form, Select, Button } from "antd";
import { Formik } from "formik";
import React from "react";
import { benefitsToTable, mapBenifitsToArray } from "../../../../common/common";

const PartnerRewards = (props) => {
  const { editable, partner, onSubmit } = props;
  const { Option } = Select;
  const rewards =
    partner.benefits.length > 0 ? benefitsToTable(partner.benefits) : null;

  const formValues = { ...rewards, tier: partner.tier };

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, actions) => {
        console.log({ values, actions });
        const tier = values.tier;
        delete values.tier;
        let benefits = mapBenifitsToArray(values);
        onSubmit({ benefits, tier });
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        handleReset,
        errors,
        touched,
        setFieldValue,
      }) => (
        <Row>
          <Col span={24}>
            {rewards ? (
              <Form onSubmitCapture={handleSubmit}>
                {editable ? (
                  <Row>
                    <Form.Item label="Partner Tier">
                      <Select
                        onChange={(e) => {
                          setFieldValue("tier", e);
                        }}
                        defaultValue="Tier 1"
                      >
                        <Option value="1">Tier 1</Option>
                        <Option value="2">Tier 2</Option>
                        <Option value="3">Tier 3</Option>
                      </Select>
                    </Form.Item>
                  </Row>
                ) : (
                  <Form.Item label="Partner Tier">
                    <a>Tier {partner.tier}</a>
                  </Form.Item>
                )}
                <div className="rounded card">
                  <table className="rewards-table" style={{ width: "100%" }}>
                    <thead className="rewards-table-header">
                      <tr>
                        <th className="rewards-table-head">Card Type</th>
                        <th className="rewards-table-head">Gold</th>
                        <th className="rewards-table-head">Silver</th>
                        <th className="rewards-table-head">Bronze</th>
                      </tr>
                    </thead>
                    <tbody>
                      {editable ? (
                        <React.Fragment>
                          <tr>
                            <td>Required Points</td>
                            <td>
                              <Input value={values.gold.requiredPoints} />
                            </td>
                            <td>
                              <Input value={values.silver.requiredPoints} />
                            </td>
                            <td>
                              <Input value={values.bronze.requiredPoints} />
                            </td>
                          </tr>
                          <tr>
                            <td>Discount</td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "gold.discount",
                                    e.target.value
                                  );
                                }}
                                value={values.gold.discount}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "silver.discount",
                                    e.target.value
                                  );
                                }}
                                value={values.silver.discount}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "bronze.discount",
                                    e.target.value
                                  );
                                }}
                                value={values.bronze.discount}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Discount For</td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "gold.discountFor",
                                    e.target.value
                                  );
                                }}
                                value={values.gold.discountFor}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "silver.discountFor",
                                    e.target.value
                                  );
                                }}
                                value={values.silver.discountFor}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "bronze.discountFor",
                                    e.target.value
                                  );
                                }}
                                value={values.bronze.discountFor}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Description</td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "gold.description",
                                    e.target.value
                                  );
                                }}
                                value={values.gold.description}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "silver.description",
                                    e.target.value
                                  );
                                }}
                                value={values.silver.description}
                              />
                            </td>
                            <td>
                              <Input
                                onChange={(e) => {
                                  setFieldValue(
                                    "bronze.description",
                                    e.target.value
                                  );
                                }}
                                value={values.bronze.description}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <tr>
                            <td>Required Points</td>
                            <td>{rewards.gold.requiredPoints}</td>
                            <td>{rewards.silver.requiredPoints}</td>
                            <td>{rewards.bronze.requiredPoints}</td>
                          </tr>
                          <tr>
                            <td>Discount</td>
                            <td>{rewards.gold.discount}%</td>
                            <td>{rewards.silver.discount}%</td>
                            <td>{rewards.bronze.discount}%</td>
                          </tr>
                          <tr>
                            <td>Discount For</td>
                            <td>{rewards.gold.discountFor}</td>
                            <td>{rewards.silver.discountFor}</td>
                            <td>{rewards.bronze.discountFor}</td>
                          </tr>
                          <tr>
                            <td>Description</td>
                            <td>{rewards.gold.description}</td>
                            <td>{rewards.silver.description}</td>
                            <td>{rewards.bronze.description}</td>
                          </tr>
                        </React.Fragment>
                      )}
                    </tbody>
                  </table>
                </div>
                {editable ? (
                  <Button
                    style={{ marginTop: "2rem" }}
                    type="default"
                    htmlType="submit"
                  >
                    Update Rewards
                  </Button>
                ) : null}
              </Form>
            ) : (
              <h1>Sorry No Benefits available for this brand</h1>
            )}
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default PartnerRewards;
