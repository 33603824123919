import axios from "axios";

const baseURL = () => {
  const apiUrl = process.env.REACT_APP_AVOCADO_MODERATOR
    // ? process.env.REACT_APP_AVOCADO_MODERATOR
    // : "http://avocadomoderationservicedev-env.eba-yb6hgppq.ap-southeast-1.elasticbeanstalk.com/";
  return apiUrl;
};

const getInstance = () => {
  const token = JSON.parse(localStorage.getItem("avocado-token"));
  return axios.create({
    headers: { Authorization: `Bearer ${token}` },
  });
};

const handleResponse = (response) => {
  return {
    body: response && response.data ? response.data.body : null,
    errors: response && response.data ? response.data.errors : {},
    status: response && response.status ? response.status : 500,
    message: response && response.data ? response.data.message : "",
    paging: response && response.data ? response.data.paging : null,
  };
};

const handleError = (errorObject) => {
  console.log(errorObject);
  const data = errorObject.response
    ? errorObject.response.data
    : { data: { status: 500, message: errorObject.message } };
  throw new Error(data.message);
};

const AvocadoModerator = {
  getContentBucket: () => {
    let url = `${baseURL()}api/content/bucket`;
    return getInstance().get(url).then(handleResponse).catch(handleError);
  },
  approveContent:(payload) =>{
    let url=`${baseURL()}api/content/rate`;
    return getInstance().post(url,payload).then(handleResponse).catch(handleError)
  }
};

export default AvocadoModerator;
