import { Col, Form, Row, Table, Input, Divider } from "antd";
import { Formik } from "formik";
import React from "react";
import gold from "../../../../assets/gold.svg";
import silver from "../../../../assets/silver.svg";
import bronze from "../../../../assets/bronze.svg";

const Tiers = [
  {
    tier: "Stand alone partners",
    points: {
      gold: 100,
      silver: 50,
      bronze: 10,
    },
    timeLimit: 7,
    reduce: 20,
  },
  {
    tier: "Aggregated partners",
    points: {
      gold: 500,
      silver: 250,
      bronze: 50,
    },
    timeLimit: 14,
    reduce: 20,
  },
  
];

const AdminTierLimit = (props) => {
  const { editable,tiers } = props;

  return (
    <div style={{height:'384px'}} className="rounded card">
      <Formik
        initialValues={null}
        onSubmit={(values, actions) => {
          console.log({ values, actions });
          alert(JSON.stringify(values, null, 2));
          actions.setSubmitting(false);
        }}
      >
        <Row>
          <Col span={24}>
            <Form>
              <table className="tier-table" style={{ width: "100%",height:'384px'}}>
                <thead className="tier-table-header">
                  <th className="tier-table-head">Partner Type</th>
                  <th className="tier-table-head required">
                    Required Points
                    <Divider style={{ margin: "0.5rem" }}></Divider>
                    <table className="points-table">
                      <thead>
                        <tr>
                          <th className="points-head">
                            <Row justify="center">
                              {" "}
                              <img src={gold} height={20} width={20} />
                              <span style={{paddingLeft:'10px'}}>Gold</span>
                            </Row>
                          </th>
                          <th className="points-head">
                            <Row justify="center">
                              {" "}
                              <img src={silver} height={20} width={20} />
                              <span style={{paddingLeft:'10px'}}>Silver</span>
                            </Row>
                          </th>
                          <th className="points-head">
                            <Row justify="center">
                              {" "}
                              <img src={bronze} height={20} width={20} />
                              <span style={{paddingLeft:'10px'}}>Bronze</span>
                            </Row>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </th>
                  <th className="tier-table-head">
                    Time Limit <br></br>(Days)
                  </th>
                  <th className="tier-table-head">Reduce Points</th>
                </thead>
                <tbody>
                  {tiers.map((item, i) => (
                     <tr key={i}>
                      {editable  ? (
                        <React.Fragment>
                          <td className="tier">{item.tier}</td>
                          <td>
                            <table className="points-table">
                              <tr>
                                <td>
                                  <Input
                                    className="rounded points-input"
                                    value={item.points.gold}
                                  ></Input>
                                </td>
                                <td>
                                  <Input
                                    className="rounded points-input"
                                    value={item.points.silver}
                                  ></Input>
                                </td>
                                <td>
                                  <Input
                                    className="rounded points-input"
                                    value={item.points.bronze}
                                  ></Input>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td>
                            <Input
                              className="rounded points-input"
                              value={item.timeLimit}
                            ></Input>
                          </td>
                          <td style={{ width: "25%" }}>
                            <Input
                              className="rounded points-input"
                              value={item.reduce}
                            ></Input>
                          </td>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {/* =="Tier 1"?'Stand alone partners':'Aggregated partners' */}
                          <td className="tier">{item.tier}</td>

                          <td>
                            <table className="points-table">
                              <tr>
                                <td className="points">{item.points.gold}</td>
                                <td className="points">{item.points.silver}</td>
                                <td className="points">{item.points.bronze}</td>
                              </tr>
                            </table>
                          </td>

                          <td>{item.timeLimit}</td>
                          <td style={{ width: "25%" }}>{item.reduce}</td>
                        </React.Fragment>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Form>
          </Col>
        </Row>
      </Formik>
    </div>
  );
};

export default AdminTierLimit;
