import React, { createContext, useEffect, useContext } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import AvocadoAdmin from "../api/avocado.admin";
import { UserContext } from "./userContext";

export const PartnerContext = createContext(null);

export const PartnerProvider = (props) => {
  const [userState, setUser] = useContext(UserContext);
  const [partners, setPartners] = useState([]);

  const getPartners = async () => {
    try {
      let filter = { limit: 1000 };
      let result = await AvocadoAdmin.getAllBrands(filter);
      setPartners(result.body);
    } catch (error) {}
  };

  useEffect(() => {
    console.log(userState);
    if (userState && userState.permissions.role !== "moderator") {
      getPartners();
    }
  }, [userState]);

  return (
    <PartnerContext.Provider value={[partners, setPartners]}>
      {props.children}
    </PartnerContext.Provider>
  );
};
