import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import ContentRating from './Rating/content-rating';



const ModeratorRoutes = (props) => {
  const { match } = props;
  console.log(props);
  return (
    <Switch>
      <Route exact path="/moderator" component={ContentRating}></Route>
    </Switch>
  );
};

export default withRouter(ModeratorRoutes);
