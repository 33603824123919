import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import AdminModerators from "./Moderators/admin-moderators";
import AdminPartners from "./Partners/admin-partners";
import AdminPoints from "./Points/admin-points";
import AdminSinglePartner from "../Admin/Partners/admin-single-partner";

import BillingPrtnerList from '../Bills/billing-prtner-list'
import Dashboard from "../Dashboard";
import Members from "../Members";
import Content from '../Content';
import Leaderboard from '../Leaderboard';
import Campaign from "../Compaign";
import ViewCampaign from "../Compaign/viewCampaign";
import PrintInvoice from "../Bills/printInvoice";
const AdminRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Route exact path="/admin" component={Dashboard} />
      <Route
        exact
        path={`${match.url}/members`}
        component={Members}
      ></Route>
      <Route
        exact
        path={`${match.url}/content`}
        component={Content}
      ></Route>
      <Route
        exact
        path={`${match.url}/leaderboard`}
        component={Leaderboard}
      ></Route>
      <Route
        exact
        path={`${match.url}/campaigns`}
        component={Campaign}
      ></Route>
      <Route 
      exact 
      path={`${match.url}/campaigns/view/:id`} 
      component={ViewCampaign}>

      </Route>
      <Route
        exact
        path={`${match.url}/moderators`}
        component={AdminModerators}
      ></Route>
      <Route
        exact
        path={`${match.url}/partners`}
        component={AdminPartners}
      ></Route>
       <Route
        exact
        path={`${match.url}/billing`}
        component={BillingPrtnerList}
      ></Route> <Route
      exact
      path={`${match.url}/billing/:id`}
      component={AdminSinglePartner}
    ></Route>
    <Route
      exact
      path={`${match.url}/invoice/:no`}
      component={PrintInvoice}
    ></Route>
      <Route exact path={`${match.url}/points`} component={AdminPoints}></Route>
      <Route
        exact
        path={`${match.url}/partners/:id`}
        component={AdminSinglePartner}
      ></Route>
    </Switch>
  );
};

export default withRouter(AdminRoutes);
