import {
  Row,
  Col,
  Form,
  Select,
  Button,
  message,
  Skeleton,
  Modal,
  Rate,
  Pagination,
  Badge,
} from "antd";
import React, { Component } from "react";
import Filters from "./partials/Filters";
import ImageCard from "./partials/ImageCard";
import AvocadoAdmin from "../../api/avocado.admin";

import * as _ from "lodash";
import {
  contentFilterToParams,
  processContentFilters,
} from "./partials/common";
import { useHistory } from "react-router";
import moment from "moment";
import VideoCard from "./partials/VideoCard";

interface ContentProps {
  match: {
    path: "/admin/content" | "/partner/content";
  };
  history: useHistory;
  location: Location;
}

interface ContentState {
  content: Array<any>;
  campaigns: Array<any>;
  isLoading: boolean;
  isVisible: boolean;
  selectedImage: any;
  selectedImages: Array<any>;
  paging: any;
  filters: any;
  selectedContent: any;
  showContentModal: boolean;
}

export default class AdminContent extends Component<
  ContentProps,
  ContentState
> {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      campaigns: [],
      isLoading: false,
      isVisible: false,
      selectedImage: {},
      selectedImages: [],
      paging: null,
      filters: {},
      selectedContent: null,
      showContentModal: false,
    };

    this.isAdmin = this.isAdmin.bind(this);

    this.getContent = this.getContent.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setContentModal = this.setContentModal.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onDeselectAll = this.onDeselectAll.bind(this);
    this.onBulkDownload = this.onBulkDownload.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onDeselectAll = this.onDeselectAll.bind(this);
    this.onType = this.onType.bind(this);
  }

  componentDidMount() {
    window.document.title = "Avocado - Content";

    const filters = processContentFilters(
      new URLSearchParams(this.props.location.search)
    );

    this.getCampaign(filters.filter ? filters.filter : null);
    this.getContent(filters.filter ? filters.filter : { limit: 20 });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      const filters = processContentFilters(
        new URLSearchParams(this.props.location.search)
      );

      this.getCampaign(filters.filter ? filters.filter : null);
      this.getContent(filters.filter ? filters.filter : { limit: 20 });
    }
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin/content";
  }

  async onSubmit(filters) {
    const newFilters = {
      ...filters,
      query:
        !filters.query || filters.query.length === 0 ? null : filters.query,
      rating: !filters.rating ? null : filters.rating,
      limit: 20,
    };
    this.setState({ filters: newFilters });
    let data = contentFilterToParams(newFilters);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
    this.getContent(newFilters);
  }

  async getCampaign(filters = null) {
    // this.setState({ isLoading: true });

    const user = JSON.parse(localStorage.getItem("avocado-user"));

    
    try {

      let result = await AvocadoAdmin.getAllCampaignNames();
      
      this.setState({ campaigns: result.body });
    } catch (error) {}
    // this.setState({ isLoading: false });
  }
  async getContent(filters = { limit: 20 }) {
    this.setState({ isLoading: true });

    const user = JSON.parse(localStorage.getItem("avocado-user"));

    if (this.isAdmin()) {
      if (!filters["partner"]) filters["partner"] = "all";
    } else {
      filters["partner"] = user.permissions.scope;
    }

    try {
      let params = { ...filters };
      // if (params["partner"] === "all") {
      delete params["partner"];
      // }
      let result = await AvocadoAdmin.getContentById(
        params,
        filters["partner"]
      );
      
      this.setState({ content: result.body, paging: result.paging });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  setContentModal(value) {
    this.setState({ isVisible: value });
  }

  onSelect(image) {
    console.log("this is the candidate", image);
    let result;
    if (!_.includes(this.state.selectedImages, image)) {
      result = [...this.state.selectedImages, image];
    } else {
      result = this.state.selectedImages.filter((item) => {
        if (item._id === image._id) {
          return false;
        } else return true;
      });
    }

    console.log(result);
    this.setState({ selectedImages: result });
  }

  onSelectAll() {
    this.setState({ selectedImages: this.state.content });
  }

  onDeselectAll() {
    this.setState({ selectedImages: [] });
  }

  onType(value) {
    console.log(value, this.state.filters);
    let filters = { ...this.state.filters, type: value, limit: 20 };
    this.setState({ filters });
    let data = contentFilterToParams(filters);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
  }

  onBulkDownload() {}

  onSort(key) {
    let temp;
    if (key === "date") {
      temp = _.sortBy(this.state.content, ["lastUpdateAt"]);
      console.log("sorted data", temp);
    } else if (key === "rating") {
      temp = _.sortBy(this.state.content, ["rating"]);
      console.log("sorted data", temp);
    }

    this.setState({ content: temp });
  }

  render() {
    const { Option } = Select;
    const {
      selectedImages,
      selectedContent,
      showContentModal,
      paging,
      filters,
    } = this.state;
console.log(paging);

    const params = processContentFilters(
      new URLSearchParams(this.props.location.search)
    );
      
    return (
      <React.Fragment>
        <div className="filter">
          <Filters
            filters={params}
            onSubmit={this.onSubmit}
            isAdmin={this.isAdmin()}
          />
        </div>
        <div className="container">
          <div style={{ marginBottom: "1.6rem" }}>
            <Row>
              <Col span={8}>
                <div className="justify-start align-center">
                  {selectedImages.length > 0 ? (
                    <h6>{selectedImages.length} Content Selected</h6>
                  ) : (
                    <h6>{paging ? paging.totalDocs : null} Content Found</h6>
                  )}
                  <div
                    style={{
                      paddingLeft: "1.6rem",
                    }}
                  >
                    {selectedImages.length > 0 ? (
                      <Button
                        onClick={() => this.onDeselectAll()}
                        type="default"
                        style={{
                          marginRight: "0.6rem",
                        }}
                      >
                        Deselect All
                      </Button>
                    ) : (
                      <Button
                        onClick={() => this.onSelectAll()}
                        type="default"
                        style={{
                          marginRight: "0.6rem",
                        }}
                      >
                        Select All
                      </Button>
                    )}
                    <Button
                      type="primary"
                      disabled={
                        !this.state.selectedImages ||
                        this.state.selectedImages.length === 0
                      }
                    >
                      Download
                    </Button>
                  </div>
                </div>
              </Col>
              <Col span={8}>
                <Form.Item
                  style={{ marginBottom: 0, marginLeft: "1rem" }}
                  label="Sort By"
                >
                  <Select
                    onChange={(e) => {
                      this.onType(e);
                    }}
                    defaultValue={null}
                  >
                    <Option value={null}>All</Option>
                    <Option value="image">Images</Option>
                    <Option value="video">Videos</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  style={{ marginBottom: 0, marginLeft: "1rem" }}
                  label="Campaign"
                >
                  <Select
                    onChange={(e) => {
                      this.onType(e);
                    }}
                    defaultValue={null}
                  >
                    <Option value={null}>All</Option>
                    {this.state.campaigns && this.state.campaigns.map(item=>{
                      return <Option value={item.title}>{item.title}</Option>
                    })}
                    
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        {this.state.isLoading ? (
          <div className="container">
            <Skeleton active></Skeleton>
          </div>
        ) : (
          <div className="container">
            <Row gutter={[20, 20]} className="container ">
              {this.state.content.map((image, i) => (
                <Col key={i} span={6}>
                  <div>
                    {/* <Badge  style={{ backgroundColor: '#52c41a' }} count={image.rating}> */}
                    {image.contentType === "image" ? (
                      <ImageCard
                        onSelect={() => {
                          console.log("SELECT");
                          this.setState({
                            showContentModal: true,
                            selectedContent: image,
                          });
                        }}
                        onChecked={this.onSelect}
                        selectedImages={this.state.selectedImages}
                        key={i}
                        image={image}
                      />
                    ) : (
                      <VideoCard
                        onSelect={() => {
                          console.log("SELECT");
                          this.setState({
                            showContentModal: true,
                            selectedContent: image,
                          });
                        }}
                        onChecked={this.onSelect}
                        selectedImages={this.state.selectedImages}
                        key={i}
                        video={image}
                      />
                    )}

                    {/* </Badge> */}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        )}
        <div
          className="justify-center text-center container"
          style={{
            padding: "0rem 0rem 2rem 0rem",
          }}
        >
          {paging && this.state.content.length > 0 ? (
            <Pagination
              current={paging.page}
              pageSize={paging.limit}
              total={paging.totalDocs}
              onChange={(page, size) => {
                let newFilterState = {
                  ...filters,
                  page: page,
                  limit: size,
                };
                let data = contentFilterToParams(newFilterState);
                this.props.history.push(`${this.props.match.path}?${data}`);
              }}
            />
          ) : null}
        </div>
        <Modal
          visible={showContentModal}
          onCancel={() => {
            this.setState({
              showContentModal: false,
              selectedContent: null,
            });
          }}
          width={800}
          okText="Download"
          footer={null}
          title={null}
          bodyStyle={{
            paddingTop: "4rem",
            paddingBottom: "2rem",
            paddingRight: "2rem",
          }}
          destroyOnClose={true}
        >
          {selectedContent ? (
            <Row gutter={24}>
              <Col span={12}>
                <div
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    borderRadius: 10,
                  }}
                >
                  {selectedContent.contentType === "image" ? (
                    <img
                      src={selectedContent.contentURL}
                      alt={selectedContent.caption}
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  ) : (
                    <video
                      style={{
                        maxWidth: "820px",
                        maxHeight: "580px",
                        objectFit: "contain",
                        backgroundColor: "black",
                      }}
                      height={"auto"}
                      width={"100%"}
                      controls={true}
                      src={selectedContent.contentURL}
                      autoPlay={true}
                    ></video>
                  )}
                </div>
              </Col>
              <Col span={12}>
                <h4>
                  {selectedContent.tags.map((tag, i) => (
                    <span key={i} style={{ fontWeight: "inherit" }}>
                      #{tag}{" "}
                    </span>
                  ))}
                </h4>
                <div>
                  <Rate
                    disabled
                    value={parseInt(selectedContent.rating)}
                    count={5}
                  />
                </div>
                {selectedContent.caption ? (
                  <div>
                    <p>{selectedContent.caption}</p>
                  </div>
                ) : null}
                <div
                  className="inner-row space-between align-center border-top "
                  style={{
                    fontSize: "1rem",
                    padding: "0.6rem 0rem",
                    marginTop: "1.6rem",
                  }}
                >
                  <span style={{ opacity: 0.6 }}>Content Id</span>
                  <span>
                    {selectedContent ? (
                      <h6>{selectedContent._id} </h6>
                    ) : (
                      <h6>-</h6>
                    )}
                  </span>
                </div>
                <div
                  className="inner-row space-between align-center border-top border-bottom"
                  style={{
                    fontSize: "1rem",
                    padding: "0.6rem 0rem",
                  }}
                >
                  <span style={{ opacity: 0.6 }}>Uploaded By</span>
                  <span>
                    {selectedContent.memberId ? (
                      <h6>
                        {selectedContent.memberId.firstName}{" "}
                        {selectedContent.memberId.lastName}
                      </h6>
                    ) : (
                      <h6>-</h6>
                    )}
                  </span>
                </div>
                {this.isAdmin() ? (
                  <div
                    className="inner-row space-between align-center  border-bottom"
                    style={{
                      fontSize: "1rem",
                      padding: "0.6rem 0rem",
                    }}
                  >
                    <span style={{ opacity: 0.6 }}>Brand</span>
                    <span>
                      {selectedContent.brandId ? (
                        <h6>
                          {selectedContent.brandId.name
                            ? selectedContent.brandId.name
                            : "-"}
                        </h6>
                      ) : (
                        <h6>-</h6>
                      )}
                    </span>
                  </div>
                ) : null}
                <div
                  className="inner-row space-between align-center  border-bottom"
                  style={{
                    fontSize: "1rem",
                    padding: "0.6rem 0rem",
                  }}
                >
                  <span style={{ opacity: 0.6 }}>Uploaded Date</span>
                  <span>
                    {selectedContent.createdAt ? (
                      <h6>
                        {moment(selectedContent.createdAt).format("DD/MM/YYYY")}
                      </h6>
                    ) : (
                      <h6>-</h6>
                    )}
                  </span>
                </div>

                <div className="inner-row justify-end">
                  <a
                    href={
                      selectedContent.originalContentURL
                        ? selectedContent.originalContentURL
                        : selectedContent.contentURL
                    }
                    target="__blank"
                    rel="noopener noreferrer"
                    download="file"
                  >
                    <Button type="primary">Download</Button>
                  </a>
                </div>
              </Col>
            </Row>
          ) : null}
        </Modal>
      </React.Fragment>
    );
  }
}
