import React, {} from "react";
import { Col, Row } from "antd";

import moment from 'moment';
import { extent } from 'd3-array';
import { timeDay } from 'd3-time';

import { Area } from '@ant-design/charts';

const generateTimeSeriesData = (data, dateField, valueField) => {
  if (!data) return [];

  let timeseriesData = [];

  const dateRange = extent(data, d => d[dateField]);
  const valueRange = extent(data, d => d[valueField]);

  const dateArray = timeDay.range(new Date(dateRange[0]), new Date(dateRange[1]));
  for (let d of dateArray) {
    const dateStr = moment(d).format('YYYY-MM-DD');
    let tmpVal = 0;

    let found = data.find(elem => elem[dateField] === dateStr);
    if (found) tmpVal = found[valueField];

    timeseriesData.push({
      [dateField]: dateStr,
      [valueField]: tmpVal,
    });
  }

  return timeseriesData;
}


const TotalContent = (props) => {
  const { content, users, dailyBreakdown } = props;
  const formattedDailyCount = generateTimeSeriesData(dailyBreakdown, '_id', 'count');

  var chartConfig = {
    data: formattedDailyCount,
    xField: '_id',
    yField: 'count',
    xAxis: { 
      tickCount: 5,
    },
    yAxis: {
      tickInterval: 1,
      grid: {
        line: {
          style: {
            strokeOpacity: 0.15,
          }
        }
      }
    },
    autoFit: true,
    areaStyle: {
      fill: 'l(270) 0:#ffffff 0.5:#5CAD464D 1:#5CAD46',
    },
    line: {
      color: '#5CAD46',
      size: 2,
    }
  };

  return (
    <div className="card inner">
      {content && users ? (
        <React.Fragment>
          <Row justify="space-between">
            <Col>
              <h6>Total Content Created</h6>
              <h1>{content.created}</h1>
            </Col>
            <Row style={{ fontSize: "1rem" }}>
              <Col>
                <div className="submetrics border-right">
                  <p>Content Per Active User</p>
                  <b>
                    {(
                      Math.round((content.created / users.activeUsers) * 100) /
                      100
                    ).toFixed(2)}
                  </b>
                </div>
              </Col>
              <Col>
                <div className="submetrics border-right">
                  <p>Average Rating</p>
                  <b>
                    {(Math.round(content.averageRating * 100) / 100).toFixed(2)}
                  </b>
                </div>
              </Col>
              <Col>
                <div className="submetrics">
                  <p>Rejection Rate</p>
                  <b>
                    {(
                      Math.round((content.rejected / content.created) * 100) /
                      100
                    ).toFixed(2)}
                    %
                  </b>
                </div>
              </Col>
            </Row>
          </Row>
          <div style={{ padding: '1.6rem 0rem', display: 'flex' }}>
            <Area {...chartConfig} style={{ flex: 1 }} />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default TotalContent;
