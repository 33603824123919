import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Tag,
  Avatar,
  Modal,
  Switch,
  Popconfirm,
} from "antd";
import { useHistory } from "react-router";
import TextArea from "antd/lib/input/TextArea";
import { Formik } from "formik";
import React from "react";
import ImageUpload from "./ImageUpload.jsx";
import LocationSearchInput from "./LocationSearchInput";

import AvocadoAdmin from "../api/avocado.admin";
import { linksToForm } from "../common/common";

const BASE_WIDTH = 374;
const BASE_HEIGHT = 190;

const PartnerDetailsForm = ({ partner, onSubmit, isAdmin, ...props }) => {
  const history = useHistory();
  const formValues = {
    email: partner.email,
    profilePicture: partner.profilePicture,
    coverPicture: partner.coverPicture,
    description: partner.description,
    phoneNumber: partner.phoneNumber,
    tags: partner.tags,
    name: partner.name,
    links:
      partner.links.length > 0
        ? linksToForm(partner.links)
        : {
            facebook: {
              type: "facebook",
              url: "",
            },
            instagram: {
              type: "instagram",
              url: "",
            },
            website: {
              type: "website",
              url: "",
            },
          },
    location: partner.location,
    isVisible: false,
    type: partner.type ? partner.type : "standard",
  };

  const validate = (values) => {
    const errors: any = {};

    if (!values.email) errors.email = "Required";
    if (!values.profilePicture) errors.profilePicture = "Required";
    if (!values.coverPicture) errors.coverPicture = "Required";
    if (!values.description) errors.description = "Required";
    if (!values.phoneNumber) errors.phoneNumber = "Required";
    if (!values.name) errors.name = "Required";
    if (!values.location) errors.location = "Required";

    console.log(errors);
    return errors;
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={formValues}
        onSubmit={async (values, actions) => {
          delete values.isVisible;
          console.log(values);
          const links = Object.values(values.links);
          onSubmit({ ...values, links });
          actions.setSubmitting(false);
        }}
        validate={validate}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          handleReset,
          isSubmitting,
          errors,
          touched,
          setFieldValue,
        }) => (
          <Form
            className="partner-edit-form"
            onSubmitCapture={handleSubmit}
            layout="vertical"
            colon={false}
          >
            <Col span={24}>
              <Row gutter={[20, 20]}>
                <Col span={14}>
                  <Form.Item label="Profile Photo">
                    <Row align="middle">
                      <Avatar
                        size={70}
                        src={values.profilePicture}
                        shape="square"
                        style={{
                          marginRight: "0.8rem",
                        }}
                      />

                      <ImageUpload
                        aspect={1}
                        onFileUpload={(imageUrl) => {
                          setFieldValue("profilePicture", imageUrl);
                        }}
                      />

                      {/* <Button type="primary" className="rounded">
                        Change
                      </Button> */}
                    </Row>
                    {errors.profilePicture && touched.profilePicture && (
                      <div id="validate">{errors.profilePicture}</div>
                    )}
                  </Form.Item>

                  <Row style={{ marginTop: "2rem" }}>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Name">
                            <Input
                              name="name"
                              onChange={handleChange}
                              value={values.name}
                              onBlur={handleBlur}
                            />
                            {errors.name && touched.name && (
                              <div id="validate">{errors.name}</div>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Address">
                            <LocationSearchInput
                              value={values.location.formattedAddress}
                              onLocationUpdated={(locationData) => {
                                console.log(locationData);
                                const formattedLocation = {
                                  geo: {
                                    coordinates: [
                                      locationData.location_lng,
                                      locationData.location_lat,
                                    ],
                                  },
                                  formattedAddress:
                                    locationData.formatted_address,
                                };

                                setFieldValue("location", formattedLocation);
                              }}
                            />
                            {/* {errors.location && touched.location && (
                              <div id="validate">{errors.location}</div>
                            )} */}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Email">
                            <Input
                              name="email"
                              onChange={handleChange}
                              value={values.email}
                              onBlur={handleBlur}
                            />
                            {errors.email && touched.email && (
                              <div id="validate">{errors.email}</div>
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Phone">
                            <Input
                              name="phoneNumber"
                              value={values.phoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <div id="validate">{errors.phoneNumber}</div>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Website">
                        <Input
                          name="website"
                          value={
                            values.links.website ? values.links.website.url : ""
                          }
                          onChange={(e) => {
                            setFieldValue("links.website.url", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {isAdmin && !props.isB2B ? (
                    <Row>
                      <Col span={24}>
                        <Form.Item label="Aggregator">
                          <Popconfirm
                            title={
                              values.type === "standard"
                                ? "Are You Sure You Want to Change To An Aggregator ?"
                                : "Are You Sure You Want to Change To A Standard Partner?"
                            }
                            icon={
                              <QuestionCircleOutlined
                                style={{ color: "red" }}
                              />
                            }
                            onConfirm={() =>
                              values.type === "standard"
                                ? setFieldValue("type", "aggregator")
                                : setFieldValue("type", "standard")
                            }
                          >
                            <Switch
                              checked={
                                values.type === "aggregator" ? true : false
                              }
                            />
                          </Popconfirm>
                        </Form.Item>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
                <Col span={10}>
                  <Row justify="end">
                    <div
                      style={{
                        width: BASE_WIDTH,
                        height: BASE_HEIGHT,
                        borderRadius: 8,
                        overflow: "hidden",
                      }}
                    >
                      <img
                        alt="Brand Cover"
                        height="100%"
                        width="100%"
                        src={values.coverPicture}
                      />
                    </div>
                  </Row>
                  <Row style={{ marginTop: "1rem" }} justify="end">
                    <ImageUpload
                      width={BASE_WIDTH * 4}
                      height={BASE_HEIGHT * 4}
                      onFileUpload={(imageUrl) => {
                        setFieldValue("coverPicture", imageUrl);
                      }}
                    ></ImageUpload>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="Tags">
                    <div
                      style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 12,
                        padding: "0.6rem 4rem 0.6rem 1rem",
                      }}
                    >
                      {values.tags.map((tag) => (
                        <Tag
                          style={{
                            backgroundColor: "#EBEBEB",
                            color: "black",
                            borderRadius: "10px",
                            border: 0,
                          }}
                          closable
                          onClose={() => {
                            if (values.tags) {
                              let newTags = values.tags.filter((item) => {
                                if (item === tag) {
                                  return false;
                                } else return true;
                              });
                              setFieldValue("tags", newTags);
                            }
                          }}
                          key={tag}
                        >
                          {tag}
                        </Tag>
                      ))}
                      {values.isVisible ? (
                        <Input
                          type="text"
                          size="small"
                          className="tag-input"
                          onBlur={(e) => {
                            let tag = e.target.value;
                            if (tag) {
                              setFieldValue("tags", [...values.tags, tag]);
                            }
                            setFieldValue("isVisible", false);
                          }}
                          autoFocus
                          onPressEnter={(e) => {
                            console.log(e.currentTarget.value);
                            let tag = e.currentTarget.value;
                            if (tag) {
                              setFieldValue("tags", [...values.tags, tag]);
                            }
                            setFieldValue("isVisible", false);
                          }}
                        />
                      ) : (
                        <Tag onClick={() => setFieldValue("isVisible", true)}>
                          <PlusOutlined /> New Tag
                        </Tag>
                      )}
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item label="About">
                    <TextArea
                      name="description"
                      onChange={handleChange}
                      value={values.description}
                      onBlur={handleBlur}
                    />
                    {errors.description && touched.description && (
                      <div id="validate">{errors.description}</div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col span={24}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item label="Facebook URL">
                        <Input
                          name="facebook"
                          value={
                            values.links.facebook
                              ? values.links.facebook.url
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue("links.facebook", {
                              type: "facebook",
                              url: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label="Instagram URL">
                        <Input
                          name="instagram"
                          value={
                            values.links.instagram
                              ? values.links.instagram.url
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue("links.instagram", {
                              type: "instagram",
                              url: e.target.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  loading={isSubmitting}
                  onClick={() => handleSubmit()}
                  type="primary"
                >
                  Update Profile
                </Button>
              </Form.Item>
            </Col>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default PartnerDetailsForm;
