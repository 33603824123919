import { Button, Card, Col, Form, Input, message, Row } from "antd";
import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";
import "./sign-in.scss";

import logo from "../../../assets/logo.svg";
import { Formik } from "formik";
import AvocadoAdmin from "../../../api/avocado.admin";
import { UserContext } from "../../../context/userContext";

export default class SignIn extends Component<any, any> {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  async setPartner(user) {
    try {
      let result = await AvocadoAdmin.getBrandById(
        null,
        user.permissions.scope
      );
      localStorage.setItem("avocado-partner", JSON.stringify(result.body));
    } catch (error) {
      localStorage.clear()
      message.error("Error getting brand");
    }
  }

  componentDidMount() {
    const [userState, setUser] = this.context;
    if (userState) {
      if (userState.permissions.role === "admin") {
        this.props.history.push("/admin");
      } else if (userState.permissions.role === "brand") {
        this.props.history.push("/partner");
      } else {
        this.props.history.push("/moderator");
      }
    }
  }
  render() {
    const { history } = this.props;
    const [userState, setUser] = this.context;

    // console.log(userState)
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values, actions) => {
            console.log({ values, actions });
            actions.setSubmitting(true);
            this.setState({ isSubmitting: true });
            try {
              let result = await AvocadoAdmin.signIn(values);
              const { user, token } = result.body;
              localStorage.setItem("avocado-user", JSON.stringify(user));
              localStorage.setItem("avocado-token", JSON.stringify(token));
              setUser(user);
              if (user.permissions.role === "admin") {
                history.push("/admin");
              } else if (user.permissions.role === "brand") {
                await this.setPartner(user);
                const brand = JSON.parse(
                  localStorage.getItem("avocado-partner")
                );
                if (!brand.hasUpdated) {
                  history.push("/partner/profile");
                } else {
                  history.push("/partner");
                }
              } else if (user.permissions.role === "moderator") {
                history.push("/moderator");
              }
            } catch (error) {
              message.error("Your Email or Password Is Incorrect");
            }
            this.setState({ isSubmitting: false });

            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            setSubmitting,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Row justify="center" align="middle" className="sign-in-page">
              <Col className="content">
                <Row className="logo">
                  <img
                    style={{
                      maxWidth: 200,
                    }}
                    alt="Avocado"
                    src={logo}
                  />
                </Row>

                <Row>
                  <Col span={24} className="card inner">
                    <div className="justify-center">
                      <h3 className="text-center">Sign In</h3>
                      <Form
                        onSubmitCapture={handleSubmit}
                        className="inner-row"
                        layout="vertical"
                        colon={false}
                      >
                        <Form.Item label="Email">
                          <Input
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className="input-with-border"
                          ></Input>
                        </Form.Item>
                        <Form.Item label="Password">
                          <Input
                            className="input-with-border"
                            name="password"
                            onChange={handleChange}
                            value={values.password}
                            type="password"
                          ></Input>
                        </Form.Item>

                        <Button
                          htmlType="submit"
                          type="default"
                          block
                          loading={this.state.isSubmitting}
                        >
                          Sign In
                        </Button>

                        <div className="text-center inner-row">
                          Forgot Password?{" "}
                          <Link
                            style={{
                              color: "#5cad46",
                              fontWeight: 700,
                            }}
                            to="/forgot-password"
                          >
                            Reset
                          </Link>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
