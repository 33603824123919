import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Input, Button, Divider, Form } from "antd";
import { Formik } from "formik";
import React from "react";

const AdminPartnersFilter = ({ onSubmit, filters, ...props }) => {
  const { Search } = Input;
  return (
    <div className="subheader">
      <Row className="container">
        <Col span={4}>
          <p className="page-header">Partners</p>
        </Col>
        <Col span={20}>
          <Formik
            initialValues={{
              query: filters.filter && filters.filter.query ? filters.filter.query : "",
            }}
            onSubmit={(values, actions) => {
              console.log(values);
              onSubmit(values);
              actions.setSubmitting(true);
            }}
          >
            {({ values, handleChange, handleSubmit, setFieldValue }) => (
              <Form onSubmitCapture={handleSubmit} colon={false}>
                <Row style={{ float: "right" }}>
                  <Form.Item className="inline-form-item">
                    <Input
                      style={{ width: "250px" }}
                      placeholder="Search"
                      value={values.query}
                      name="query"
                      onChange={handleChange}
                      className="rounded"
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>
                  <Button
                    htmlType="submit"
                    className="inline-form-item"
                    type="default"
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => props.setAddModal(true)}
                    type="primary"
                  >
                    + Add Partner
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPartnersFilter;
