import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Popconfirm, Select, Switch } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Formik } from "formik";
import React from "react";

const AddPartner = (props) => {
  const { isAdd, setAddModal, onSubmit, categories } = props;
  const { Option } = Select;
  return (
    <React.Fragment>
      <Modal
        visible={isAdd}
        title="Add Partner"
        className="add-partner-modal rounded"
        style={{ borderRadius: "12px" }}
        onCancel={() => setAddModal(false)}
        footer={null}
      >
        <Formik
          initialValues={{
            name: "",
            email: "",
            category: "",
            tier: "",
          }}
          onSubmit={(values, actions) => {
            console.log({ values, actions });
            onSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form
              onSubmitCapture={handleSubmit}
              className="add-partner"
              layout="vertical"
            >
              <Form.Item label="Name">
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item label="Category">
                <Select
                  onChange={(e) => {
                    setFieldValue("category", e);
                    console.log(e);
                    if (e === "work") setFieldValue("tier", "1");
                  }}
                  value={values.category}
                >
                  {categories.map((item, i) => (
                    <Option value={item.slug}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Tier ">
                <Select
                  onChange={(e) => {
                    setFieldValue("tier", e);
                    console.log(e);
                  }}
                  value={values.tier}
                  disabled={values.category === "work" ? true : false}
                >
                  <Option value="1">Tier 1</Option>
                  <Option value="2">Tier 2</Option>
                  <Option value="3">Tier 3</Option>
                </Select>
              </Form.Item>

              <div style={{ textAlign: "right" }}>
                <Button key="submit" htmlType="submit" type="default">
                  Add Partner
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default AddPartner;
