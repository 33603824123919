import React, { createContext, useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";

export const UserContext = createContext(null);

export const UserProvider = (props) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("avocado-user"));
  const [userState, setUser] = useState(user ? user : null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (userState && !ready) {
      const pathName = props.location.pathname;

      setReady(true);
      if (pathName === "/") {
        if (user.permissions.role === "admin") {
          setReady(true);
          history.push("/admin");
        } else if (user.permissions.role === "brand") {
          setReady(true);
          history.push("/partner");
        } else {
          setReady(true);
          history.push("/moderator");
        }
      } else {
        history.push(`${pathName}${props.location.search}`);
      }
    } else {
      setReady(true);
      const pathName = props.location.pathname;
      if (pathName === "/reset-password") {
        history.push(`${pathName}${props.location.search}`);
      } else {
        history.push("/");
      }
    }
  }, []);

  return (
    <UserContext.Provider value={[userState, setUser]}>
      {props.children}
    </UserContext.Provider>
  );
};
