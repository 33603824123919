import { GoogleCircleFilled, MailFilled, PhoneFilled } from "@ant-design/icons";
import { Row, Col, Table, Tag, Skeleton, Modal, message, Form, Input, Button } from "antd";
import React, { Component } from "react";
import AdminSinglePartnerFilter from "./Partials/admin-single-partner-filter";
import PartnerDetails from "../../../components/partner-details";
import PartnerBills from "../../../components/partner-bill";
import PartnerDetailsForm from "../../../components/partner-details-form";
import PartnerRewards from "./Partials/partner-rewards";
import AvocadoAdmin from "../../../api/avocado.admin";
import { stat } from "fs";
import { useHistory } from "react-router";

import Partner from "../../Partner/Partner";

interface SinglePartnerProps {
  match: {
    path: "/admin/partners/:id";
  };
  history: useHistory;
}

export default class AdminSinglePartner extends Component<
  SinglePartnerProps,
  any
> {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      selected: "DETAILS",
      partnerId: props.match.params.id,
      partner: null,
      isBlocked:false,
      openCapModal:false,
    };

    this.onChangeEdit = this.onChangeEdit.bind(this);
    this.onChangeSelected = this.onChangeSelected.bind(this);
    this.getBrand = this.getBrand.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.removePartner = this.removePartner.bind(this);
    this.removePartnerModal = this.removePartnerModal.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.resetPasswordModal = this.resetPasswordModal.bind(this);
    this.isAdmin = this.isAdmin.bind(this);
    this.blcokPartner = this.blcokPartner.bind(this);
    this.onUpdateCap = this.onUpdateCap.bind(this);
    this.updateCap = this.updateCap.bind(this);
  }

  componentDidMount() {
    if (this.props.match.path !== "/admin/partners/:id") {
      this.setState({ selected: "BILLING" });
    }
    this.getBrand();
  }

  onChangeEdit(value) {
    this.setState({ editable: value });
  }
  onUpdateCap(value) {
    this.setState({ openCapModal: value });
  }
  onChangeSelected(value) {
    this.setState({ selected: value });
  }

  async onSubmit(values) {
    this.setState({ isLoading: true, editable: false });
    console.log(values);
    try {
      let result = await AvocadoAdmin.updateBrand(values, this.state.partnerId);
      this.setState({ partner: result.body });
    } catch (error) {
      console.log(error);
      message.error("Error Updating Partner..");
    }
    this.setState({ isLoading: false });
  }

  async updateCap(values) {
    this.setState({ isLoading: true });
    try {
      let result = await AvocadoAdmin.updateCap(this.state.partnerId,{ amount: values.amount } );
      message.success("Successfully Billing Cap Updated");
      this.setState({ openCapModal: false })
    } catch (error) {
      console.log(error);
      message.error("Error Updating Partner..");
    }
    this.setState({ isLoading: false });
  }
  async getBrand() {
    this.setState({ isLoading: true });

    try {
      let result = await AvocadoAdmin.getBrandById(null, this.state.partnerId);
      this.setState({ partner: result.body, isBlocked: result.body.blocked });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async removePartnerModal() {
    const self = this;
    Modal.confirm({
      title: `Are you sure you want to remove ${this.state.partner.name}?`,
      okText: "Yes",
      cancelText: "No",
      className: "remove-partner-modal",
      okButtonProps: {
        className: "ok-btn",
        onClick: () => this.removePartner(),
      },
      cancelButtonProps: {
        className: "cancel-btn",
        type: "primary",
      },
    });
  }

  async removePartner() {
    message.loading("Deleting Partner");
    try {
      let result = await AvocadoAdmin.deleteBrand(this.state.partnerId);
      message.destroy();
      Modal.destroyAll();
      message.success("Partner Deleted Successfully");
      this.props.history.push("/admin/partners");
    } catch (error) {
      message.destroy();
    }
  }

  async resetPasswordModal() {
    const self = this;
    Modal.confirm({
      title: `Are you sure you want to reset password for ${self.state.partner.name}?`,
      okText: "Yes",
      cancelText: "No",
      className: "remove-partner-modal",
      okButtonProps: {
        className: "ok-btn",
        onClick: () => self.resetPassword(),
      },
      cancelButtonProps: {
        className: "cancel-btn",
        type: "primary",
      },
    });
  }

  async resetPassword() {
    message.loading("Resetting Password");
    Modal.destroyAll();
    console.log(this.state.partner);

    try {
      let result = await AvocadoAdmin.forgotPassword({
        email: this.state.partner.user
          ? this.state.partner.user.email
          : this.state.partner.email,
      });
      message.destroy();
      Modal.success({
        content: `A password reset link has been sent to ${this.state.partner.user.email} `,
      });
    } catch (error) {
      message.error("Error Occured while resetting passwordƒ");
    }
  }
  async blcokPartner() {
   console.log('ddd',this.state.partnerId,this.state.isBlocked);
   
    try {
      message.loading( this.state.isBlocked ? "Unblocking Partner ..." : "Blocking Partner ...");

      let result = await AvocadoAdmin.blockPartner(this.state.partnerId,{ blocked: this.state.isBlocked ? 'false' : 'true'});
      this.setState({ partner: result.body, isBlocked: !this.state.isBlocked });
     
    } catch (error) {
      message.error("Error Occured while blocking partner");
    }
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin/partners/:id";
  }

  isB2B(): Boolean {
    return this.state.partner ? this.state.partner.isB2B : false;
  }

  render() {
    const { state } = this;
console.log(this.props.match['params'].id);

    return (
      <React.Fragment>
        <div className="filter">
          <AdminSinglePartnerFilter
          isBlocked={this.state.isBlocked}
            onChangeEdit={this.onChangeEdit}
            onUpdateCap={this.onUpdateCap}
            blcokPartner={this.blcokPartner}
            onChangeSelected={this.onChangeSelected}
            removePartner={this.removePartnerModal}
            selected={state.selected}
            editable={state.editable}
            onResetPassword={this.resetPasswordModal}
            isB2B={this.isB2B()}
          ></AdminSinglePartnerFilter>
        </div>

        {state.isLoading === false ? (
          <React.Fragment>
            <div className="container">
              {state.selected === "DETAILS" && (
                <React.Fragment>
                  {state.editable ? (
                    <PartnerDetailsForm
                      partner={state.partner}
                      onSubmit={this.onSubmit}
                      isAdmin={this.isAdmin()}
                      isB2B={this.isB2B()}
                    ></PartnerDetailsForm>
                  ) : (
                    <PartnerDetails
                      isAdmin={this.isAdmin()}
                      partner={state.partner}
                    ></PartnerDetails>
                  )}
                </React.Fragment>
              )}
              {state.selected === "REWARDS" && (
                <PartnerRewards
                  onSubmit={this.onSubmit}
                  partner={state.partner}
                  editable={state.editable}
                ></PartnerRewards>
              )}
              {state.selected === "BILLING" && (
                <PartnerBills brandId={this.props.match['params'].id} isAdmin={true}></PartnerBills>
              )}
            </div>
          </React.Fragment>
        ) : (
          <div className="container">
            <Skeleton active />
          </div>
        )}
        <Modal
          // title="Are you sure you want to remove this campaign?"
          centered
          visible={this.state.openCapModal}
          footer={null}
          onOk={() => {
            this.setState({ openCapModal: false });
          }}
          onCancel={() => {
            this.setState({ openCapModal: false });
          }}
          width={610}
        >
          <div>
            <Row>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 22,
                  fontWeight: 600,
                  marginBottom: 30,
                }}
              >
                Edit Billing Cap
              </p>
            </Row>
            <Form
              name="basic"
              className="add-partner"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={this.updateCap}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              
                
              <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true, message: 'Please enter billing cap!' }]}
              >
                <Input type="number" />
              </Form.Item>
              

              <Row style={{ justifyContent: "flex-end" }}>
                <Button htmlType="submit" style={{ width: 70 }} type="primary">
                Save
                </Button>
              </Row>
            </Form>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
