import { Button, Input, Select, Form } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Formik } from "formik";
import React from "react";

const AddModerator = (props) => {
  const { isAdd, setAddModal, onSubmit } = props;

  return (
    <React.Fragment>
      <Modal
        visible={isAdd}
        title="Add Moderator"
        className="add-partner-modal rounded"
        style={{ borderRadius: "12px" }}
        onCancel={() => setAddModal(false)}
        footer={null}
      >
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
          }}
          onSubmit={(values, actions) => {
            console.log({ values, actions });
            onSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Form
              onSubmitCapture={handleSubmit}
              className="add-partner"
              layout="vertical"
            >
              <Form.Item label="First Name">
                <Input
                  value={values.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item label="Last Name">
                <Input
                  value={values.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                />
              </Form.Item>
              <div style={{ textAlign: "right" }} className="modal-footer">
                <Button key="submit" htmlType="submit" type="default">
                  Add Moderator
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default AddModerator;
