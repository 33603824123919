import { Button, Card, Col, Form, Input, message, Row } from "antd";
import React, { Component, useContext } from "react";

import logo from "../../assets/logo.svg";
import { Formik } from "formik";
import AvocadoAdmin from "../../api/avocado.admin";
import { UserContext } from "../../context/userContext";

export default class ForgotPassword extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  componentDidMount() {
    // console.log("sign in was called", userState);
  }
  render() {
    const { history } = this.props;

    // console.log(userState)
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={async (values, actions) => {
            console.log({ values, actions });
            actions.setSubmitting(true);
            this.setState({ isSubmitting: true });
            try {
              let result = await AvocadoAdmin.forgotPassword(values);
              message.success("Email sent. Please check your email.")
              history.push("/")
            } catch (error) {
              message.error("Oops, Something went wrong");
            }
            this.setState({ isSubmitting: false });

            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            setSubmitting,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Row justify="center" align="middle" className="sign-in-page">
              <Col className="content">
                <Row className="logo">
                  <img
                    style={{
                      maxWidth: 200,
                    }}
                    alt="Avocado"
                    src={logo}
                  />
                </Row>

                <Row>
                  <Col span={24} className="card inner">
                    <div className="justify-center">
                      <h3 className="text-center">Reset Your Password</h3>
                      <p>
                        Please enter your email address. We'll email you a link
                        to reset your password.
                      </p>
                      <Form
                        onSubmitCapture={handleSubmit}
                        className="inner-row"
                        layout="vertical"
                        colon={false}
                      >
                        <Form.Item label="Email">
                          <Input
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            className="input-with-border"
                          ></Input>
                        </Form.Item>

                        <Button
                          htmlType="submit"
                          type="default"
                          block
                          loading={this.state.isSubmitting}
                        >
                          Reset Password
                        </Button>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
