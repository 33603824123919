import React, { Component } from "react";
import {
  Col,
  Pagination,
  Row,
  Skeleton,
  Table,
  Tag,
  Avatar,
  message,
  Modal,
  Form,
  Button,
  Input,
  Select,
} from "antd";
import { Formik } from "formik";
import Filters from "./partials/Filters";
// Data
import AvocadoAdmin from "../../api/avocado.admin";
import { campaignFilterToParams, processCampaignFilters } from "./common";
const DEFAULT_MEMBER_PROFILE_IMAGE =
  "https://avacado-partner-bucket-dev.s3-ap-southeast-1.amazonaws.com/placeholder_images/avocado-avatar.jpg";

const { Option } = Select;
const { TextArea } = Input;

export default class BillingPrtnerList extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingCountries: false,
      showRefundModal: false,
      showRefundConfirmModal:false,
      paging: {
        totalDocs: 0,
      },
      amount:0, 
      reason:'', 
      id:"",
      // leaderboard: [],
      partners:[],
      partner: "all",
      filters: {},
      history: [],
      countries:[],
    };

    this.isAdmin = this.isAdmin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getCountries=  this.getCountries.bind(this);
    this.getPartners=  this.getPartners.bind(this);
    this.confirmRefund=  this.confirmRefund.bind(this);
    this.sendRefund=  this.sendRefund.bind(this);

  }

  componentDidMount() {
    window.document.title = "Avocado - Billing";
    const filters = processCampaignFilters(
      new URLSearchParams(this.props.location.search)
    );

    this.getAllHistory(filters.filter ? filters.filter : {});
    this.getAllHistory();
    this.getCountries();
    this.getPartners();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      const filters = processCampaignFilters(
        new URLSearchParams(this.props.location.search)
      );

      this.getAllHistory(filters.filter ? filters.filter : {});
      this.getCountries();
      this.getPartners();
    }
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin/billing";
  }

  async onSubmit(filter) {
    this.setState({
      partner: filter.partner,
      filters: filter,
    });
    let data = campaignFilterToParams(filter);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
  }

  async getAllHistory(filters: any = {}) {
    this.setState({ isLoading: true });
    let params = { ...filters };
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getAllBilling(params);
      this.setState({ history: result.body, paging: result.paging });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async getCountries() {
    this.setState({ isLoadingCountries: true });
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getCountries();
      
      this.setState({ countries: result.body.countries, isLoadingCountries: false });
      console.log(result);
      
    } catch (error) {}
    this.setState({ isLoadingCountries: false });
  }
  async getPartners() {
    this.setState({ isLoading: true });
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getAllBrands();
      this.setState({ partners: result.body });
      console.log(result);
      
    } catch (error) {}
    this.setState({ isLoading: false });
  }
  confirmRefund(values: any){
    console.log("innnn");
    
    this.setState({
      amount:values.amount,
      reason:values.reason,
      id:values.partner,
      showRefundModal: false,
      showRefundConfirmModal:true });
  }
  async sendRefund(values: any) {
    this.setState({ isLoading: true });
    const {amount, reason, id}  = this.state;
    try {
      // message.loading("Loading...");
      let payload = {
        "amount": amount,
        "reason": reason,
        "password": values.password,
    }
      let result = await AvocadoAdmin.sendRefund(id,payload);
      message.success('Successfully refunded')
      this.setState({showRefundConfirmModal:false})
      
    } catch (error) {
      console.log(error);
      
      message.error("Invalid Password")
    }
    this.setState({ isLoading: false });
  }
  render() {
    const columns = [
      {
        title: "Partner Name",
        dataIndex: "brand",
        key: "name",
        render: (partner) => (
          <div
            style={{
              textAlign: "left",
              color: "#1B2014",
              fontSize: 16,
              fontWeight: 600,
            }}
            onClick={() =>
              this.props.history.push(`billing/${partner._id}`)
            }
          >
            <img
              style={{ width: 50, height: 50, marginRight: 30 }}
              src={partner.profilePicture}
            />
            {partner.name}
          </div>
        ),
      },
      {
        title: "Category",
        dataIndex: "brand",
        key: "category",
        render: (brand) => (
          <div
            style={{
              color: "#1B2014",
              fontSize: 16,
              fontWeight: "normal",
              textAlign: "center",
            }}
          >
            {brand.category}
          </div>
        ),
      },
      {
        title: "Active Members",
        dataIndex: "billableMembers",
        key: "address",
        render: (text) => (
          <div style={{ color: "#1B2014", fontSize: 16, fontWeight: "normal" }}>
            {text && text!=0 ?  text : "-"}
          </div>
        ),
      },
      {
        title: "Bill Amount",
        dataIndex: "billAmount",
        key: "amount",
        render: (text) => (
          <div style={{ color: "#1B2014", fontSize: 16, fontWeight: "normal" }}>
            {text && text!=0 ? "$" + text : "-"}
          </div>
        ),
      },
      {
        title: "Billing Cap",
        dataIndex: "billingCap",
        key: "billingCap",
        render: (text) => (
          <div style={{ color: "#1B2014", fontSize: 16, fontWeight: "normal" }}>
            {text && text!=0 ? "$" + text : "-"}
          </div>
        ),
      },
      // {
      //   title: "Invoice",
      //   dataIndex: "invoice",
      //   key: "invoice",
      //   render: (text, record) => (
      //     <img src={pdfimage} style={{ width: 24, height: 33 }} />
      //   ),
      // },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (status) => (
          <Tag
            color={status === "success" ? "green" : "default"}
            style={{
              background: "transparent",
              padding: "5px 15px",
              borderRadius: 10,
              borderColor: status === "refunded"?"#1B2014":status === "pending" ? "gray":"",
              color: status === "pending" ? "gray" : status === "success" ?"#5CAD46": "#1B2014",
            }}
          >
            {status ==='success' ? 'Paid': status}
          </Tag>
        ),
      },
    ];

     
    const { campaigns , countries} = this.state;
    const filters = processCampaignFilters(
      new URLSearchParams(this.props.location.search)
    );

    return (
      <React.Fragment>
        <div className="filter">
          <Filters
            showModal={(show) => {
              this.setState({ showRefundModal: show });
            }}
            filters={filters}
            onSubmit={this.onSubmit}
            isAdmin={this.isAdmin()}
            selectedPartner={this.state.partner}
            rank={this.state.paging ? this.state.paging.totalDocs : 0}
          />
        </div>
        <div className="container">
          <Table
            style={{ textAlign: "left" }}
            columns={columns}
            dataSource={this.state.history}
          />
        </div>
        <Modal
          // title="Are you sure you want to remove this campaign?"
          centered
          visible={this.state.showRefundModal}
          footer={null}
          onOk={() => {
            this.setState({ showRefundModal: false });
          }}
          onCancel={() => {
            this.setState({ showRefundModal: false });
          }}
          width={610}
        >
          <div>
            <Row>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 22,
                  fontWeight: 600,
                  marginBottom: 30,
                }}
              >
                Send Refund
              </p>
            </Row>
            <Form
              name="basic"
              className="add-partner"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={this.confirmRefund}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item name="country" label="Country"
              rules={[
                { required: true, message: "Please pick  country!" },
              ]}
              >
                <Select
                  defaultValue={null}
                  id="country"
                  showSearch
                  bordered={true}
                >
                  <Option value={null}>Select</Option>
                  {countries && countries.map(item=>{
                    return(<Option value={item.code}>{item.name}</Option>)
                  })}
                  
                </Select>
              </Form.Item>
              <Form.Item name="partner" label="Partner" 
              rules={[
                { required: true, message: "Please pick partner!" },
              ]}>
                <Select defaultValue={null} id="partner" showSearch>
                  <Option value={null}>Select</Option>
                  {this.state.partners && this.state.partners.map((item, index)=>{
                    return(<Option key={index} value={item._id}>{item.name}</Option>)
                  }) }
                </Select>
              </Form.Item>
              <Form.Item name="amount" label="Amount" 
              rules={[
                { required: true, message: "Please add amount" },
              ]}>
                <Input type="number"  />
              </Form.Item>
              <Form.Item name="reason" label="Reason" rules={[
                { required: true, message: "Please add reason!" },
              ]}>
                <TextArea bordered={true} style={{ border: "none" }} rows={3} />
              </Form.Item>

              <Row style={{ justifyContent: "flex-end" }}>
                <Button htmlType="submit" style={{ width: 100 }} type="primary">
                  Continue
                </Button>
              </Row>
            </Form>
          </div>
        </Modal>


       <Modal
          // title="Are you sure you want to remove this campaign?"
          centered
          visible={this.state.showRefundConfirmModal}
          footer={null}
          onOk={() => {
            this.setState({ showRefundConfirmModal: false });
          }}
          onCancel={() => {
            this.setState({ showRefundConfirmModal: false });
          }}
          width={610}
        >
          <div>
            <Row>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 22,
                  fontWeight: 600,
                  marginBottom: 30,
                }}
              >
                Send Refund
              </p>
            </Row>
            <Form
              name="basic"
              className="add-partner"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={this.sendRefund}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              
                
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password />
              </Form.Item>
              

              <Row style={{ justifyContent: "flex-end" }}>
                <Button htmlType="submit" style={{ width: 300 }} type="primary">
                Verify and Send Refund
                </Button>
              </Row>
            </Form>
          </div>
        </Modal>
        
      </React.Fragment>
    );
  }
}
