import React, { useState } from "react";
import axios from "axios";
import ImgCrop from "antd-img-crop";
import { Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AvocadoAdmin from "../api/avocado.admin";

const generateFileName = (file) => {
  let timestamp = new Date().getTime();
  return `capmpaign_${timestamp}_${file.name}`;
};

const CampaignImageUpload = (props) => {
  const [fileList, setFileList] = useState([]);

  return (
    <ImgCrop width={props.width} height={props.height}  resize={true}>
      <Upload
        customRequest={async (e) => {
          const file = e.file;
          setFileList([file]);

          if (file) {
            try {
              const res = await AvocadoAdmin.getCampaignUploadURL({
                fileName: file.name,
                fileType: file.type,
              });

              const uploadURL = res.body.signedUrlInfo;
              const fileURL = res.body.publicUrl;

              var options = {
                headers: {
                  "Content-Type": file.type,
                },
              };


              try {
                await axios.put(uploadURL, file, options);
                e.onSuccess({
                  file: file,
                });
                console.log("fileupload");
                if (props.onFileUpload) props.onFileUpload(fileURL);
              } catch (uploadError) {
                console.log(uploadError);
              }
            } catch (e) {
              message.error("File upload error!");
            }
          }
        }}
        multiple={false}
        fileList={fileList}
        // onRemove={() => {
        //   setFileList([]);
        // }}
      >
        {props.children}
      </Upload>
    </ImgCrop>
  );
};

export default CampaignImageUpload;
