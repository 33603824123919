import React, { FunctionComponent, useContext } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Row, Form, Select, Button } from "antd";
import { Formik } from "formik";
import { PartnerContext } from "../../../context/partnerContext";

const { Option } = Select;

interface FiltersProps {
  FilterOnSubmit: Function;
  isAdmin?: boolean;
  filters: any;
}

const Filters: FunctionComponent<FiltersProps> = ({
  FilterOnSubmit,
  isAdmin,
  filters,
}) => {
  const memberFilterValues = {
    query: filters.filter && filters.filter.query ? filters.filter.query : "",
    partner:
      filters.filter && filters.filter.partner ? filters.filter.partner : "all",
    status:
      filters.filter && filters.filter.status ? filters.filter.status : null,
  };
  const [partners, setPartners] = useContext(PartnerContext);

  return (
    <div className="subheader">
      <Row className="container">
        <Col span={4}>
          <h3>Members</h3>
        </Col>
        <Col span={20}>
          <div>
            <Formik
              initialValues={memberFilterValues}
              onSubmit={(values, actions) => {
                console.log({ values, actions });
                const tmpValues = {
                  ...values,
                  query: values.query.replace(/#/gi, ""),
                };
                FilterOnSubmit(tmpValues);
                actions.setSubmitting(false);
              }}
            >
              {({ values, handleChange, handleSubmit, setFieldValue }) => (
                <Form onSubmitCapture={handleSubmit} colon={false}>
                  <Row style={{ float: "right" }}>
                    <Form.Item className="inline-form-item">
                      <Input
                        style={{ width: "250px" }}
                        name="query"
                        placeholder="Search"
                        value={values.query}
                        prefix={<SearchOutlined />}
                        onChange={handleChange}
                        type="search"
                      />{" "}
                    </Form.Item>

                    {isAdmin ? (
                      <Form.Item className="inline-form-item" label="Partners">
                        <Select
                          id="partner"
                          defaultValue="all"
                          value={values.partner}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(e) => {
                            setFieldValue("partner", e);
                          }}
                        >
                          <Option value="all">All</Option>
                          {partners.map((brand, i) => (
                            <Option key={i} value={brand._id}>
                              {brand.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}

                    <Form.Item className="inline-form-item" label="Status">
                      <Select
                        defaultValue={null}
                        value={values.status}
                        id="status"
                        onChange={(e) => {
                          setFieldValue("status", e);
                        }}
                      >
                        <Option value={null}>All</Option>
                        <Option value={1}>Active</Option>
                        <Option value={0}>Inactive</Option>
                      </Select>
                    </Form.Item>

                    <Button htmlType="submit" type="default">
                      Apply
                    </Button>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
