import { Button, Card, Col, Form, Input, message, Row } from "antd";
import React, { Component, useContext } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.svg";
import { Formik } from "formik";
import AvocadoAdmin from "../../api/avocado.admin";
import { UserContext } from "../../context/userContext";

export default class ResetPassword extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    // console.log("sign in was called", userState);
  }

  async submitForm(values) {
    const { props } = this;
    const searchParams = new URLSearchParams(props.location.search);

    const params = {
      newPassword: values.new_password,
      userId: searchParams.get("userId"),
      code: searchParams.get("resetCode"),
    };

    console.log(params);
    AvocadoAdmin.resetPassword(params)
      .then((results) => {
        if (results.status === 200) {
          message.success("Your password has been reset successfully!");
          props.history.push("/");
        }
      })
      .catch((e) => {
        message.error("Oops. Something went wrong!");
      });
  }
  render() {
    const { history } = this.props;

    // console.log(userState)
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            new_password: "",
            confirmPassword: "",
          }}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            await this.submitForm(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            setSubmitting,
            isSubmitting,
            errors,
            touched,
            setFieldValue,
          }) => (
            <Row justify="center" align="middle" className="sign-in-page">
              <Col className="content">
                <Row className="logo">
                  <img
                    style={{
                      maxWidth: 200,
                    }}
                    alt="Avocado"
                    src={logo}
                  />
                </Row>

                <Row>
                  <Col span={24} className="card inner">
                    <div className="justify-center">
                      <h3 className="text-center">Reset Your Password</h3>

                      <Form
                        onSubmitCapture={handleSubmit}
                        className="inner-row"
                        layout="vertical"
                        colon={false}
                      >
                        <Form.Item label="New Password">
                          <Input
                            name="new_password"
                            onChange={handleChange}
                            type="password"
                          />
                        </Form.Item>
                        <Form.Item label="Confirm Password">
                          <Input
                            name="confirmPassword"
                            onChange={handleChange}
                            type="password"
                          />
                        </Form.Item>

                        <Button
                          htmlType="submit"
                          type="default"
                          block
                          loading={isSubmitting}
                        >
                          Reset Password
                        </Button>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}
