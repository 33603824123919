import { SearchOutlined } from "@ant-design/icons";
import { Select,Space, Row, Col, Input, Button, Form, DatePicker } from "antd";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext } from "react";
import { PartnerContext } from "../../../context/partnerContext";

const Filters = ({ onSubmit, isAdmin, filters }) => {
  const { Option } = Select;

  const { RangePicker } = DatePicker;
  const [partners, setPartners] = useContext(PartnerContext);

  const filterValues = {
    query: filters.filter && filters.filter.query ? filters.filter.query : "",
    partner:
      filters.filter && filters.filter.partner ? filters.filter.partner : "all",
    rating:
      filters.filter && filters.filter.rating ? filters.filter.rating : null,
    startDate:
      filters.filter && filters.filter.startDate
        ? filters.filter.startDate
        : null,
    endDate:
      filters.filter && filters.filter.endDate ? filters.filter.endDate : null,
  };
  const [value, setValue] = React.useState([]);
  interface ItemProps {
    label: string;
    value: any;
  }
  const options: ItemProps[] = [
  ];
  if(isAdmin){
    options.push(
      { label: "Approved", value: null },
      { label: "Rejected", value: 0 },
      { label: "1 Star", value: 1 },
      { label: "2 Star", value: 2 },
      { label: "3 Star", value: 3 },
      { label: "4 Star", value: 4 },
      { label: "5 Star", value: 5 },)
  }else{
    options.push(
      { label: "Approved", value: null },
      { label: "1 Star", value: 1 },
      { label: "2 Star", value: 2 },
      { label: "3 Star", value: 3 },
      { label: "4 Star", value: 4 },
      { label: "5 Star", value: 5 },)
  }
  const selectProps = {
    mode: "multiple" as const,
    style: { width: "100%" },
    value,
    options,
    onChange: (newValue: string[]) => {
      setValue(newValue);
    },
    placeholder: "Select Item...",
    maxTagCount: 2 as const,
  };
  return (
    <div className="subheader">
      <Row className="container">
        <Col span={2}>
          <h3>Content</h3>
        </Col>
        <Col span={21}>
          <Row justify="end">
            <Col span={24}>
              <Formik
                initialValues={filterValues}
                onSubmit={(values, actions) => {
                  console.log(values);
                  onSubmit(values);
                  actions.setSubmitting(true);
                }}
                enableReinitialize={true}
              >
                {({
                  values,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  handleReset,
                  errors,
                  touched,
                  setFieldValue,
                }) => (
                  <Form onSubmitCapture={handleSubmit} colon={false}>
                    <Row >
                      <Col span={11}>
                        {isAdmin ? (
                          <Row>
                            <Col span={12}>
                              <Form.Item className="inline-form-item">
                                <Input
                                  style={{
                                    width: "200px",
                                    float: "right",
                                    marginRight: 10,
                                  }}
                                  placeholder="Search"
                                  value={values.query}
                                  name="query"
                                  onChange={handleChange}
                                  className="rounded"
                                  prefix={<SearchOutlined />}
                                />{" "}
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                className="inline-form-item"
                                label="Partners"
                                style={{ paddingRight: 10 }}
                              >
                                <Select
                                  defaultValue="all"
                                  value={values.partner}
                                  id="partner"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(e) => {
                                    setFieldValue("partner", e);
                                  }}
                                >
                                  <Option value="all">All</Option>
                                  {partners.map((partner, i) => (
                                    <Option key={i} value={partner._id}>
                                      {partner.name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : (
                          <Form.Item
                            className="inline-form-item"
                            style={{ alignItems: "flex-end" }}
                          >
                            <Input
                              style={{
                                width: "200px",
                                float: "right",
                                marginRight: 10,
                              }}
                              placeholder="Search"
                              value={values.query}
                              name="query"
                              onChange={handleChange}
                              className="rounded"
                              prefix={<SearchOutlined />}
                            />{" "}
                          </Form.Item>
                        )}
                      </Col>
                      <Col span={6}>
                        <Form.Item
                        name="rating"
                          className="inline-form-item"
                          label="Rate"
                          style={{ paddingRight: 10 }}
                        >
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Select {...selectProps} value={values.rating} onChange={(e) => {
                                    setFieldValue("rating", e);
                                  }}/>{" "}
                          </Space>{" "}
                          {/* <Select
                            defaultValue={null}
                            value={values.rating}
                            id="rating"
                            onChange={(e) => {
                              setFieldValue("rating", e);
                            }}
                          >
                            <Option value={null}>Approved</Option>
                            {isAdmin ? (
                              <Option value="0">Rejected</Option>
                            ) : null}
                            <Option value="1">1 Star</Option>
                            <Option value="2">2 Star</Option>
                            <Option value="3">3 Star</Option>
                            <Option value="4">4 Star</Option>
                            <Option value="5">5 Star</Option>
                          </Select> */}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Date"
                          className="inline-form-item"
                          style={{ paddingRight: 10 }}
                        >
                          <RangePicker
                            value={
                              values.startDate && values.endDate
                                ? [
                                    moment(values.startDate),
                                    moment(values.endDate),
                                  ]
                                : [null, null]
                            }
                            ranges={{
                              Today: [moment(), moment()],
                              Yesterday: [
                                moment().subtract(1, "day"),
                                moment().subtract(1, "day"),
                              ],
                              "This Week": [
                                moment().startOf("week"),
                                moment().endOf("week"),
                              ],
                              "Last Week": [
                                moment().subtract(1, "week").startOf("week"),
                                moment().subtract(1, "week").endOf("week"),
                              ],
                              "This Month": [
                                moment().startOf("month"),
                                moment().endOf("month"),
                              ],
                              "Last Month": [
                                moment().subtract(1, "month").startOf("month"),
                                moment().subtract(1, "month").endOf("month"),
                              ],
                            }}
                            onChange={(e) => {
                              if (e) {
                                let startDate = moment(e[0]).format(
                                  "YYYY/MM/DD"
                                );
                                let endDate = moment(e[1]).format("YYYY/MM/DD");
                                console.log(startDate, endDate);
                                setFieldValue("startDate", startDate);
                                setFieldValue("endDate", endDate);
                              } else {
                                setFieldValue("startDate", null);
                                setFieldValue("endDate", null);
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1} style={{ justifyContent: "flex-end" }}>
                        <Button htmlType="submit" type="default">
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Filters;
