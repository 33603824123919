import React, { Component, useContext } from "react";
import { Link, NavLink } from "react-router-dom";

import "./header.scss";

import logo from "../assets/logo.svg";
import Icon, { DownOutlined } from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import { Row, Col, Dropdown, Menu } from "antd";
import { UserContext } from "../context/userContext";

declare const window: any;

const Header = ({ menuItems, ...props }) => {
  let partner;
  const profile = JSON.parse(localStorage.getItem("avocado-user"));

  try {
    partner = JSON.parse(localStorage.getItem("avocado-partner"));
  } catch (e) {
    console.log(e);
  }

  const [userState, setUser] = useContext(UserContext);
  const menu = (
    <Menu
      style={{
        overflow: "hidden",
      }}
    >
      {userState ? (
        <React.Fragment>
          {" "}
          {profile.permissions.role === "brand" ? (
            <Menu.Item>
              <Link to="/partner/profile">Profile</Link>
            </Menu.Item>
          ) : null}
          <Menu.Item onClick={() => signOut()}>
            <Link to="/">Log Out</Link>
          </Menu.Item>
        </React.Fragment>
      ) : null}
    </Menu>
  );

  const signOut = () => {
    localStorage.clear();
    window.Intercom("shutdown");
    setUser(null);
  };

  // console.clear();
  //remove this when going live
  // console.log(
  //   "%c You are not supposed to be here",
  //   "font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)"
  // );

  let name = partner
    ? partner.name
    : `${profile.firstName} ${profile.lastName}`;
  let profilePicture = partner
    ? partner.profilePicture
    : profile.profilePictureURL;

  return (
    <div className="header-area">
      <header className="header">
        <div className="header-container">
          <Row align="middle" className="nav">
            <Col span={4}>
              <Row>
                <Link to="/">
                  <img
                    src={logo}
                    alt="Avocado"
                    style={{
                      height: "1.7em",
                    }}
                  />
                </Link>
              </Row>
            </Col>
            <Col span={16}>
              <Row justify="center">
                <ul className="menu-items">
                  {menuItems.map((item, i) => (
                    <li key={i}>
                      <NavLink
                        exact
                        activeClassName="is-active"
                        to={item.route}
                      >
                        {item.label}
                      </NavLink>
                    </li>
                  ))}
                  <li style={{ float: "right" }}></li>
                </ul>
              </Row>
            </Col>
            <Col span={4}>
              <div className="justify-end align-center">
                <span
                  style={{
                    color: "white",
                    fontSize: "0.9rem",
                    fontWeight: 600,
                    marginRight: "0.6rem",
                  }}
                >
                  {name}
                </span>
                <Dropdown
                  placement="bottomRight"
                  overlay={menu}
                  trigger={["hover"]}
                >
                  <a>
                    <Avatar src={profilePicture}>{name[0]}</Avatar>
                    <DownOutlined />{" "}
                  </a>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>
      </header>
    </div>
  );
};

export default Header;
