import React, { FunctionComponent } from 'react';

export const DataCard: FunctionComponent<{ title?: string, value?: string, children?: React.ReactNode }> = ({ title, value, children }) => {
    return (
        <div className="card inner">
            <h6>{title}</h6>
            {value ? <h1>{value}</h1> : null}
            {children ? children : null}
        </div>
    )
}