import {
  MailFilled,
  PhoneFilled,
  GoogleCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Modal,
  Tag,
  Form,
  Image,
  Space,
  Skeleton,
  Avatar,
  Button,
  Input,
  Card,
} from "antd";

import moment from "moment";
import React, { useState, useEffect } from "react";
import pdfimage from "../assets/pdf.png";
import IG from "../assets/ig.svg";
import FB from "../assets/fb.svg";
import AvocadoAdmin from "../api/avocado.admin";
import { message } from "antd";
import PrintInvoice from "../pages/Bills/printInvoice";
import { useHistory } from "react-router";
const payhereUrl = process.env.NODE_ENV==="development"?'https://sandbox.payhere.lk/pay/preapprove':"https://www.payhere.lk/pay/preapprove";


const PartnerBills = ({ isAdmin, brandId }) => {
  const history = useHistory();

  const [viewModal, setViewModal] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  let [preaprove, setPreaprove] = useState({address: null,
    cancel_url:null,
    return_url:null,
    notify_url:null,
  city:null,
  country: null,
  currency: null,
  custom_1: null,
  email: null,
  first_name: null,
  items: null,
  last_name: null,
  merchant_id: null,
  order_id: null,
  phone: null, });
  const [summary, setSummary] = useState({
    billableAmount: 0,
    billableFromDate: "30 Sep 2021",
    billableMembershipCount: 12,
    billableToDate: "30 Sep 2021",
    due: 0,
    dueDate: "14 Oct 2021",
    paidAmount: 0,
    billingCap: 5,
    currency:'LRK',
    accountBalance:0,
    paymentMethod: null,
  });
  useEffect(() => {
    console.log(isAdmin);
    if (isAdmin) {
      getBillingHistoryAdmin();
      getBillingSummaryAdmin();
    } else {
      getBillingHistoryPartner();
      getBillingSummaryPartner();
    }
  }, []);

  const getBillingHistoryAdmin = async () => {
    message.loading("Loading...");
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getBillingHistoryAdmin(brandId);
      setData(result.body);
    } catch (error) {}
  };

  const getBillingHistoryPartner = async () => {
    message.loading("Loading...");
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getBillingHistoryPartner();
      setData(result.body);
    } catch (error) {}
  };
  const getBillingSummaryAdmin = async () => {
    try {
      message.loading("Loading...");
      let result = await AvocadoAdmin.getBillingSummaryAdmin(brandId);
      setSummary(result.body);
    } catch (error) {}
  };

  const getBillingSummaryPartner = async () => {
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getBillingSummaryPartner();
      setSummary(result.body);
    } catch (error) {}
  };
  const preAprove  = async () => {
    try {
      message.loading("Loading...");
      let result = await AvocadoAdmin.getPayherePreAprove();
      setPreaprove(result.body)
      setViewModal(true);
    } catch (error) {}
  };
  // const printInvoice () =>{}
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <div
          style={{
            textAlign: "left",
            color: "#1B2014",
            fontSize: 16,
            fontWeight: "normal",
          }}
        >
          {moment(text).format("DD MMM YYYY")}
        </div>
      ),
    },
    {
      title: "Reference",
      dataIndex: "orderNo",
      key: "orderNo",
      render: (text) => (
        <div style={{ color: "#1B2014", fontSize: 16, fontWeight: "normal" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Memberships",
      dataIndex: "billableMembershipCount",
      key: "billableMembershipCount",
      render: (text) => (
        <div style={{ color: "#1B2014", fontSize: 16, fontWeight: "normal" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <div style={{ color: "#1B2014", fontSize: 16, fontWeight: "normal" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Invoice",
      dataIndex: "orderNo",
      key: "orderNo",
      render: ( orderNo) => (
        
        <img src={pdfimage} onClick={()=>{isAdmin? window.open(`../invoice/${orderNo}`, "_blank"):window.open(`invoice/${orderNo}`, "_blank")}} style={{ width: 24, height: 33 }} />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
        color={status === "success" ? "green" : "default"}
        style={{
          background: "transparent",
          padding: "5px 15px",
          borderRadius: 10,
          borderColor: status === "refunded"?"#1B2014":status === "pending" ? "gray":"",
          color: status === "pending" ? "gray" : status === "success" ?"#5CAD46": "#1B2014",
        }}
      >
        {status ==='success' ? 'Paid': status}
      </Tag>
      ),
    },
  ];

  const onFinish = (values: any) => {};

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const style = { backgroungColr: "blue" };
  console.log(summary);

  return (
    <React.Fragment>
      <Row style={{marginTop:20}} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row" span={6}>
          <div>
            <Card style={{ minHeight: 248, padding: 20 }}>
              <div style={{ fontSize: 16, fontWeight: 600, color: "#1B2014" }}>
                Monthly Due
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: "normal",
                  color: "#1B2014",
                  opacity: 0.6,
                }}
              >
                {moment(summary.billableFromDate).format("DD MMM YYYY") } -  {moment(summary.billableToDate).format("DD MMM YYYY") }
              </div>

              <div
                style={{ fontSize: 48, fontWeight: "bold", color: "#1B2014" }}
              >
                {summary.currency} {summary.due}
              </div>
              <div
                style={{ fontSize: 14, fontWeight: "normal", color: "#5CAD46" }}
              >
                Pay before {moment(summary.dueDate).format('DD MMM yyyy')}
              </div>
              <div
                style={{
                  borderTop: "1px solid #DEDEDE",
                  marginTop: 20,
                  paddingTop: 20,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ fontSize: 16, fontWeight: 600, color: "#1B2014" }}
                >
                  Billing Cap
                </div>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    color: "#1B2014",
                    opacity: 0.6,
                  }}
                >
                   {summary.billingCap > 1000000? "_" : summary.currency +" "+ summary.billingCap}
                </div>
              </div>
            </Card>
          </div>
          <div>
            <Card style={{ minHeight: 119, padding: 20, marginTop: 20 }}>
              <div style={{ fontSize: 16, fontWeight: 600, color: "#1B2014" }}>
                Account Balance
              </div>
              <div
                style={{
                  fontSize: 36,
                  fontWeight: "bold",
                  color: "#1B2014",
                  opacity: 1,
                }}
              >
                {summary.currency} {summary.accountBalance}
              </div>
            </Card>
          </div>
          <div>
            <Card style={{ minHeight: 235, padding: 20, marginTop: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ fontSize: 16, fontWeight: 600, color: "#1B2014" }}
                >
                  Payment Method
                </div>
                {!isAdmin && (<div
                  style={{
                    fontSize: 14,
                    fontWeight: "normal",
                    color: "#CECECE",
                    opacity: 0.6,
                    cursor:'pointer'
                  }}
                  onClick={() => {
                    preAprove()
                    
                  }}
                >
                  {summary.paymentMethod ?'Change':'Add'}
                </div>)}
              </div>
              {summary.paymentMethod ? (
                <Card
                  style={{
                    padding: "20px",
                    height: 152,
                    background:
                    summary.paymentMethod ?" linear-gradient(123deg, rgba(92,173,64,1) 0%, rgba(249,212,105,1) 100%)":"#D9D9D9",
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      marginBottom: 60,
                      fontWeight: "normal",
                      color: "#FFFFFF",
                    }}
                  >
                    {summary.paymentMethod ? 'Credit Card' :'Card detail not Icluded'}
                  </div>
                  <div
                    style={{ fontSize: 16, fontWeight: 600, color: "#FFFFFF" }}
                  >{summary.paymentMethod ? (summary.paymentMethod.card_no).replace('************','XXXX-XXXX-XXXX-'):''}
                  </div>
                </Card>
              ) : (
                !isAdmin && <Button
                  style={{
                    marginTop: 20,
                    width: "100%",
                    height: 152,
                    background: "#D9D9D9",
                    color: "#5CAD46",
                    fontSize: 16,
                  }}
                  onClick={() => {
                    preAprove()
                    
                  }}
                >
                  + Add Your Card
                </Button>
              )}
            </Card>
          </div>
        </Col>

        <Col className="gutter-row" span={18}>
          <div
            style={{
              fontSize: 28,
              fontWeight: "bold",
              color: "#1B2014",
              paddingBottom: 30,
            }}
          >
            History
          </div>
          <Table
            key="_id"
            style={{ textAlign: "left" }}
            columns={columns}
            dataSource={data}
          />
        </Col>
      </Row>
      <Modal
        // title="Approved"
        centered
        visible={viewModal}
        footer={null}
        width={600}
        style={{ padding: 35 }}
        onCancel={() => {
          setViewModal(false);
        }}
      >
   
        <Row style={{ justifyContent: "space-between" }}>
          <p style={{ fontSize: 22, fontWeight: "bold", marginBottom: 30 }}>
            Payment Method
          </p>
          <form
            action={payhereUrl}
            method="post"
            style={{ width: "100%" }}
          >
            {/* hidden fields */}
            <div style={{ display: "none" }}>
              <Input type="hidden" name="merchant_id" value={preaprove.merchant_id} />
              <Input
                type="hidden"
                name="return_url"
                value={preaprove.return_url}               />
              <Input
                type="hidden"
                name="cancel_url"
                value={preaprove.cancel_url} 
              />
              <Input
                type="hidden"
                name="notify_url"
                value={preaprove.notify_url}  
              />
              <Input
                type="hidden"
                name="order_id"
                value={preaprove.order_id} 
              />
              <Input type="hidden" name="items" value={preaprove.items}  />
              <br />
              <Input type="hidden" name="currency"  value={preaprove.currency} />
              <br />
              <br />
            </div>
            Customer Details
            <br />
            <label>First Name</label>
            <Input type="text" name="first_name" defaultValue={preaprove.first_name} />
            <label>Last Name</label>
            <Input type="text" name="last_name" defaultValue={preaprove.last_name} />
            <br />
            <label>Email</label>
            <Input type="text" name="email"  defaultValue={preaprove.email}  />
            <label>Phone Number</label>
            <Input type="text" name="phone" defaultValue={preaprove.phone} />
            <br />
            <label>Address</label>
            <Input
              type="text"
              name="address"
              defaultValue={preaprove.address}
            />
           
            <Input type="hidden"  name="city" defaultValue={preaprove.city} />
            <div style={{ display: "none" }}>
              <Input
                type="hidden"
                name="custom_1"
                defaultValue={preaprove.custom_1}
              />
              <Input type="hidden" name="country" defaultValue={preaprove.country} />
            </div>
            <br />
            <br />
            <Input type="submit" value={summary.paymentMethod ? 'Continue change':'Continue'} />
          </form>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

export default PartnerBills;
