import React, { FunctionComponent } from "react";
import { Avatar, Table } from "antd";

import { DataCard } from '../../../components/DataCard';

import { UserSummary } from '../types';

const TopUsers: FunctionComponent<{ userSummary?: UserSummary }> = ({ userSummary }) => {
  const Columns = [
    {
      title: () => <div className="mini-table-header justify-start"><p>Rank</p></div>,
      key: "rank",
      render: (_1, _2, index) => (<h6>#{index + 1}</h6>),
    },{
        title: () => <div className="mini-table-header justify-start"><p>User</p></div>,
        key:'name',
        render: (_1, record) => (
            <div className="justify-start align-center" style={{ textAlign: 'left', fontWeight: 600 }}>
                <Avatar 
                    src={record.member.profilePictureURL} 
                    size={40} 
                    alt={`${record.member.firstName} ${record.member.lastName}`}
                    style={{ marginRight: '1rem' }}
                >{record.member.firstName ? record.member.firstName[0] : ''}</Avatar>
                <div style={{ flex: 1 }}>{record.member.firstName} {record.member.lastName}</div>
            </div>
        ),
    },{
        title: () => <div className="mini-table-header justify-end"><p>Total Memberships</p></div>,
        render: (_1, record) => (
            <div className="justify-end align-center" style={{ fontWeight: 600  }}>
                <div>{record.memberships}</div>
            </div>
        ),
    },{
        title: () => <div className="mini-table-header justify-end"><p>Total Points</p></div>,
        render: (_1, record) => (
            <div className="justify-end align-center" style={{ fontWeight: 600  }}>
                <div>{record.points.toLocaleString()}</div>
            </div>
        ),
    }
  ];

  if (!userSummary) return null;

  return (
    <DataCard title="Top Users">
        <div 
            style={{
                marginTop: '0.6rem',
            }}
        >
            <Table 
                pagination={false}
                columns={Columns}
                dataSource={userSummary.topUsers}
                className="mini-table"
            />
        </div>
    </DataCard>
  );
};

export default TopUsers;
