import React, { Component } from "react";
import Design from "../../common/Design";
import ModeratorRoutes from "./ModeratorRoutes";

import './moderator.scss'

export default class Moderator extends Component {
  render() {
    return (
      <React.Fragment>
        <Design
          menuData={[
            // {
            //   route: "/partner",
            //   label: "Dashboard",
            // },
            // {
            //   route: "/partner/members",
            //   label: "Members",
            // },
            // {
            //   route: "/partner/content",
            //   label: "Content",
            // },
            // {
            //   route: "/partner/leaderboard",
            //   label: "Leaderboard",
            // },
          ]}
        >
            <ModeratorRoutes></ModeratorRoutes>
        </Design>
      </React.Fragment>
    );
  }
}
