import React, { Component } from "react";
import Design from "../../common/Design";
import PartnerRoutes from "./PartnerRoutes";

import './partner.scss'

export default class Partner extends Component {
  render() {
    return (
      <React.Fragment>
        <Design
          menuData={[
            {
              route: "/partner",
              label: "Dashboard",
            },
            {
              route: "/partner/members",
              label: "Members",
            },
            {
              route: "/partner/content",
              label: "Content",
            },
            {
              route: "/partner/leaderboard",
              label: "Leaderboard",
            },
            {
              route: "/partner/campaigns",
              label: "Campaigns",
            },
            {
              route: "/partner/billing",
              label: "Billing",
            },
          ]}
        >
          <PartnerRoutes></PartnerRoutes>
        </Design>
      </React.Fragment>
    );
  }
}
