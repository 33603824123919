import { Row, Col, Pagination, Table, Tag, message, Skeleton } from "antd";
import React, { Component } from "react";
import Design from "../../../common/Design";
import AdminPartnersFilter from "./Partials/admin-partners-filter";
import AvocadoAdmin from "../../../api/avocado.admin";
import AddPartner from "./Partials/add-partner";
import { partnerFilterToParams, processPartnerFilters } from "./common";

export default class AdminPartners extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      partners: [],
      categories: [],
      isAdd: false,
      isLoading: false,
      paging: {},
    };
    this.getPartners = this.getPartners.bind(this);
    this.setAddModal = this.setAddModal.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.addBrand = this.addBrand.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    window.document.title = "Avocado - Partners";

    const filters = processPartnerFilters(
      new URLSearchParams(this.props.location.search)
    );
    this.getPartners(filters.filter ? filters.filter : null);
    this.getCategories();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      const filters = processPartnerFilters(
        new URLSearchParams(this.props.location.search)
      );

      this.getPartners(filters.filter ? filters.filter : null);
    }
  }

  async getPartners(filter = null) {
    this.setState({ isLoading: true });
    try {
      let result = await AvocadoAdmin.getAllBrands(filter);
      this.setState({ partners: result.body, paging: result.paging });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async setAddModal(value) {
    this.setState({ isAdd: value });
  }

  async addBrand(values) {
    message.loading("Adding Partner");
    this.setAddModal(false);
    try {
      let result = await AvocadoAdmin.createBrand(values);
      this.getPartners();
    } catch (error) {}
    message.destroy();
  }

  async getCategories() {
    try {
      let result = await AvocadoAdmin.getAllCategories();
      this.setState({ categories: result.body });
    } catch (error) {}
  }

  async changePage(pageNumber, limit) {
    const filters = { page: pageNumber, limit };
    this.setState({ isLoading: true });
    console.log(filters);
    console.log("not for all");
    let result = await AvocadoAdmin.getAllBrands(filters);
    this.setState({ partners: result.body, paging: result.paging });

    this.setState({ isLoading: false });
  }

  async onSubmit(filters) {
    let data = partnerFilterToParams(filters);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
    // this.getPartners(filters);
  }

  render() {
    const { history, match } = this.props;
    const filters = processPartnerFilters(
      new URLSearchParams(this.props.location.search)
    );
    const AllColumns = [
      {
        title: "Partner Name",
        key: "name",
        render: (record, i) => (
          <React.Fragment>
            <Row key={i}>
              <Col>
                <img
                  height={50}
                  width={50}
                  className="rounded"
                  style={{ marginRight: "1rem" }}
                  src={record.profilePicture ? record.profilePicture : ""}
                />
              </Col>
              <Col style={{ alignItems: "center" }}>
                <span>{record.name}</span>
              </Col>
            </Row>
          </React.Fragment>
        ),
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Active Members",
        dataIndex: "activeMembers",
        key: "active_members",
      },
      {
        title: "Approved Content",
        dataIndex: "approvedContent",
        key: "approved_content",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: () => (
          <Tag
            className="rounded"
            style={{ background: "white" }}
            color="green"
          >
            Active
          </Tag>
        ),
      },
      // {
      //   title: "Type",
      //   dataIndex: "type",
      //   key: "type",
      // },
      {
        title: "Tier",
        dataIndex: "tier",
        key: "tier",
        render: (record, i) => (
          <Row justify="center" align="middle" key={i}>
            <span>Tier {record}</span>
          </Row>
        ),
      },
    ];
    return (
      <React.Fragment>
        <Row className="filter">
          <Col span={24}>
            <AdminPartnersFilter
              filters={filters}
              setAddModal={this.setAddModal}
              onSubmit={this.onSubmit}
            ></AdminPartnersFilter>
          </Col>
        </Row>
        <div className="container">
          <Row>
            <Col span={6}>
              <p className="count"> {this.state.paging.totalDocs} Partners</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {this.state.isLoading ? (
                <Skeleton active></Skeleton>
              ) : (
                <React.Fragment>
                  <Table
                    className="partner-table"
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: () =>
                          history.push(`${match.url}/${record._id}`),
                      };
                    }}
                    rowClassName="table-row"
                    pagination={false}
                    dataSource={this.state.partners}
                    columns={AllColumns}
                  />
                  <Pagination
                    style={{ textAlign: "center", paddingTop: "2rem" }}
                    total={this.state.paging.totalDocs}
                    onChange={(pageNumber, limit) => {
                      this.changePage(pageNumber, limit);
                    }}
                    pageSizeOptions={["10", "15", "30"]}
                    pageSize={
                      this.state.paging.limit ? this.state.paging.limit : 10
                    }
                    current={
                      this.state.paging.page ? this.state.paging.page : 1
                    }
                    showSizeChanger
                  ></Pagination>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </div>
        <AddPartner
          setAddModal={this.setAddModal}
          isAdd={this.state.isAdd}
          categories={this.state.categories}
          onSubmit={this.addBrand}
        ></AddPartner>
      </React.Fragment>
    );
  }
}
