import { Button, Input, Select, Form, Col, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import { Formik } from "formik";
import React from "react";

const EditModerator = (props) => {
  const { isEdit, setEditModal, onSubmit, moderators } = props;

  return (
    <React.Fragment>
      <Modal
        visible={isEdit}
        title="Remove Moderator"
        className="edit-moderator-modal rounded"
        style={{ borderRadius: "12px" }}
        width="700px"
        onCancel={() => setEditModal(false)}
        footer={null}
      >
        {moderators.map((moderator, i) => (
          <Row key={i} style={{ marginBottom: "1rem" }}>
            <Col span={24}>
              <Row>
                <Col span={4}>
                  <img src={""} />
                </Col>
                <Col span={12}>
                  <span>
                    {moderator.firstName} {moderator.lastName}
                  </span>
                </Col>
                <Col span={8}>
                  <Row justify="end">
                    <Button
                      style={{
                        backgroundColor: "red",
                        textAlign: "right",
                        color: "white",
                      }}
                      onClick={() => onSubmit(moderator._id)}
                    >
                      Remove Moderator
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </Modal>
    </React.Fragment>
  );
};

export default EditModerator;
