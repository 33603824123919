import querystring from "querystring";

export const campaignFilterToParams = (params) => {
  const values: any = {
    filter: params ? JSON.stringify(params) : null,
  };

  return querystring.stringify(values);
};

export const processCampaignFilters = (params) => ({
  filter: params.get("filter") ? JSON.parse(params.get("filter")) : null,
});
