import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Input, Button, Divider, Tag } from "antd";
import React from "react";

const AdminSinglePartnerFilter = (props) => {
  return (
    <div className="subheader">
      <Row className="container">
        <Col span={8}>
          <Row>
            <p className="page-header">Profile</p>
            {props.editable ? null : (
              <React.Fragment>
                <Tag
                  className="rounded tag"
                  onClick={() => props.onChangeSelected("DETAILS")}
                  color={props.selected === "DETAILS" ? "#5cad46" : null}
                >
                  Details
                </Tag>
                {props.isB2B ? null : (
                  <Tag
                    color={props.selected === "REWARDS" ? "#5cad46" : null}
                    onClick={() => props.onChangeSelected("REWARDS")}
                    className="rounded tag"
                  >
                    Rewards
                  </Tag>
                )}
                <Tag
                  className="rounded tag"
                  onClick={() => props.onChangeSelected("BILLING")}
                  color={props.selected === "BILLING" ? "#5cad46" : null}
                >
                  Billing
                </Tag>
              </React.Fragment>
            )}
          </Row>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={16}>
          {props.editable ? (
            <React.Fragment>
              <Button
                onClick={() => props.onChangeEdit(false)}
                className="inline-form-item"
                type="default"
              >
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "red", color: "white" }}
                className="inline-form-item"
                onClick={() => props.removePartner()}
              >
                Remove Partner
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                onClick={() => props.onResetPassword()}
                className="inline-form-item"
                type="primary"
              >
                Reset Password
              </Button>
              <Button
                onClick={() => props.selected === "BILLING" ? props.onUpdateCap(true) :props.onChangeEdit(true)}
                className="inline-form-item"
                type="default"
              >
                {props.selected === "BILLING"?"Billing Cap":"Edit"}
              </Button>
              <Button
              onClick={() => props.blcokPartner()} //
              style={{ backgroundColor: "red", color: "white" }}
              className="rounded submit"
            >
              {props.isBlocked? 'Unblock':'Block' }
            </Button>
            </React.Fragment>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AdminSinglePartnerFilter;
