import { SearchOutlined } from "@ant-design/icons";
import { Switch,Row, Col, Tag, Input, Select, Button, Divider, Form } from "antd";
import React from "react";

const AdminPointsFilter = (props) => {
  
  const { editable ,onChange, decayPointEnable} = props;
  return (
    <div className="subheader">
      <Row className="container">
        <Col span={8}>
          <Row>
            <p className="page-header">Points</p>
          </Row>
        </Col>
        <Col span={14}><Row justify="end">Decay points <Switch checked={decayPointEnable} onClick={onChange} style={{marginLeft:15}}/></Row></Col>
        <Col span={2}>
          <Row justify="end">
          
            {editable ? (
              <Button
                type="default"
                onClick={() => props.onEdit(false)}
                className="rounded"
              >
                {" "}
                Save
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => props.onEdit(true)}
                className="rounded"
              >
                Edit
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPointsFilter;
