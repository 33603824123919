import React, { Component } from "react";
import {
  Modal,
  Col,
  Row,
  Button,
  Input,
  Card,
  Form,
  List,
  Avatar,
  message,
} from "antd";
import star from "../../assets/star.png";
import { RightOutlined } from "@ant-design/icons";
import { campaign } from "./dump";
import { withRouter } from "react-router-dom";
import AvocadoAdmin from "../../api/avocado.admin";
import { stat } from "fs";
const DEFAULT_MEMBER_PROFILE_IMAGE =
  "https://avacado-partner-bucket-dev.s3-ap-southeast-1.amazonaws.com/placeholder_images/avocado-avatar.jpg";

export default class ViewCampaign extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      campaign: [],
      color: "#EBBB33",
      loading: false,
      visibleModal: false,
      visiblePickWinnwrModal: false,
      visibleConfirmHideModal: false,
      winners: [],
      leaderBoard: [],
      visibleApproveModal: false,
      visibleRejectModal: false,
    };
    this.getWinners = this.getWinners.bind(this);
  }

  componentDidMount() {
    this.getCampaign();
    window.document.title = "Avocado - Campaigns";
  }

  getStatus() {
    switch (this.state.campaign.status) {
      case "active":
        this.setState({ color: "#5CAD46" });
        break;
      case "approved":
        this.setState({ color: "#5CAD46" });
        break;
      case "pending":
        this.setState({ color: "#EBBB33" });
        break;
      case "completed":
        this.setState({ color: "#CECECE" });
        break;
      case "rejected":
        this.setState({ color: "#FF4141" });
        break;
      default:
        this.setState({ color: "#EBBB33" });
        break;
    }
  }
  componentDidUpdate() {}

  async getCampaign() {
    this.setState({ isLoading: true });
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getCampaignById(
        this.props.match.params.id
      );
      this.setState({
        campaign: result.body,
        paging: result.paging,
        winners: result.body.winners ? result.body.winners : [],
        leaderBoard: result.body.leaderboard ? result.body.leaderboard : [],
      });
      this.getStatus();
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async approvalCampaign(status) {
    this.setState({ visibleConfirmHideModal: false, isLoading: true });
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.approvalCampaign(
        this.props.match.params.id,
        { approved: status }
      );

      if (status) {
        this.setState({ visibleApproveModal: true });
      } else {
        this.navigate("/admin/campaigns");
      }
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async hide() {
    const { campaign } = this.state;
    this.setState({ isLoading: true });
    try {
      message.loading("Loading...");
      let result = await AvocadoAdmin.hideCampaign(this.props.match.params.id, {
        hide: campaign && campaign.status === "hidden" ? false : true,
      });

      this.navigate("/admin/campaigns");
    } catch (error) {}
    this.setState({ isLoading: false });
  }
  isAdmin() {
    return this.props.match.path === `/admin/campaigns/view/:id`;
  }
  navigate(path) {
    this.props.history.push(path);
  }
  approve(status) {
    this.setState({ visibleModal: false });
    this.approvalCampaign(status);
  }
  reject() {
    this.setState({ visibleRejectModal: true });
  }

  async getWinners(values: any) {
    this.setState({ isLoading: true });
    message.loading("Picking your winners");
    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.pickWinner(this.props.match.params.id, {
        count: values.count,
      });
      this.setState({ winners: result.body });
      this.getStatus();
    } catch (error) {}
    this.setState({ isLoading: false, visiblePickWinnwrModal: false });
  }
  renderItem(item) {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={item.user.profilePictureURL} />}
          title={
            <Row>
              <Col span={8}>
                <span style={{ color: "#1B2014", fontSize: 16 }}>
                  {item.user.firstName} {item.user.lastName}
                </span>
              </Col>
              <Col span={8}>
                <span
                  style={{ color: "#666666", fontSize: 14, paddingLeft: 5 }}
                >
                  Win Rate :
                </span>
                <span
                  style={{ color: "#5CAD46", fontSize: 14, paddingLeft: 5 }}
                >
                  {item.winRate}%
                </span>
              </Col>
              <Col span={8}>
                <span
                  style={{ color: "#666666", fontSize: 14, paddingLeft: 5 }}
                >
                  {" "}
                  Total Entreies :{" "}
                </span>
                <span
                  style={{ color: "#5CAD46", fontSize: 14, paddingLeft: 5 }}
                >
                  {item.entries}
                </span>
              </Col>
            </Row>
          }
        />
      </List.Item>
    );
  }
  render() {
    const { campaign, winners } = this.state;
    console.log(this.props.match.path === `/admin/campaigns/view/:id`);

    return (
      <React.Fragment>
        <div>
          <div
            className="subheader"
            style={{ position: "fixed", width: "100%", zIndex: 2 }}
          >
            <Row className="container">
              <Col span={18} style={{ display: "flex", alignItems: "center" }}>
                <h3
                  onClick={() => {
                    this.navigate("../../campaigns");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Campaign
                  <RightOutlined style={{ fontSize: 16, fontWeight: "bold" }} />
                  <span style={{ fontWeight: "bold" }}>
                    {campaign && campaign.title}
                  </span>
                </h3>
                <span
                  style={{
                    marginLeft: 20,
                    color: this.state.color,
                    height: 27,
                    fontSize: 12,
                    border: "2px solid",
                    padding: "3px 10px",
                    borderRadius: 5,
                  }}
                >
                  {campaign && campaign.status}
                </span>
              </Col>
              {this.isAdmin() === true ? (
                <Col
                  span={6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {campaign && campaign.status === "pending" && (
                    <div>
                      <Button
                        onClick={() => {
                          this.setState({ visibleModal: true });
                        }}
                        type="primary"
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={() => this.reject()}
                        danger
                        type="primary"
                        style={{ marginLeft: 10 }}
                      >
                        Reject
                      </Button>
                    </div>
                  )}

                  {campaign &&
                    (campaign.status === "completed" ||
                      campaign.status === "active" ||
                      campaign.status === "approved") && (
                      <div>
                        <Button
                          onClick={() => {
                            this.setState({ visibleConfirmHideModal: true });
                          }}
                          type="default"
                        >
                          Hide
                        </Button>
                      </div>
                    )}
                  {campaign && campaign.status === "hidden" && (
                    <div>
                      <Button
                        onClick={() => {
                          this.setState({ visibleConfirmHideModal: true });
                        }}
                        type="default"
                      >
                        Unhide
                      </Button>
                    </div>
                  )}
                </Col>
              ) : (
                <div>
                  {campaign &&
                    campaign.status === "completed" &&
                    campaign.type === "raffle" &&
                    this.state.winners.length === 0 && (
                      <Button
                        type="primary"
                        onClick={() => {
                          this.setState({ visiblePickWinnwrModal: true });
                        }}
                      >
                        Pick Winners
                      </Button>
                    )}
                </div>
              )}
            </Row>
          </div>
          <div>
            <Row className="container" style={{ paddingTop: 140 }}>
              <Col span={16}>
                {this.state.winners && this.state.winners.length !== 0 && (
                  <div
                    className="site-card-border-less-wrapper"
                    style={{ marginBottom: 30 }}
                  >
                    <Card
                      bordered={false}
                      style={{ width: "100%", padding: 30 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontSize: 18, fontWeight: "bold" }}>
                          Winners
                        </div>
                        <div style={{ fontSize: 14 }}>
                          <Button
                            type="default"
                            onClick={() => {
                              this.setState({ visiblePickWinnwrModal: true });
                            }}
                          >
                            Re-Pick Winners
                          </Button>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingTop: 30,
                        }}
                      >
                        <List
                          itemLayout="horizontal"
                          dataSource={winners}
                          renderItem={(item) => this.renderItem(item)}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </Card>
                  </div>
                )}
                <div
                  className="site-card-border-less-wrapper"
                  style={{ marginRight: 15, marginBottom: 30, width: "100%" }}
                >
                  <Card bordered={false} style={{ width: "100%", padding: 30 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ fontSize: 18, fontWeight: "bold" }}>
                        Campaign Overview
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {campaign &&
                          new Date(campaign.startDate).toDateString()}{" "}
                        -{" "}
                        {campaign && new Date(campaign.endDate).toDateString()}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        paddingTop: 30,
                      }}
                    >
                      <div style={{ fontSize: 16, paddingRight: 35 }}>
                        Partner :{" "}
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#5CAD46",
                            paddingLeft: 5,
                          }}
                        >
                          {campaign && campaign.brand && campaign.brand.name}
                        </span>
                      </div>
                      <div style={{ fontSize: 16 }}>
                        Campaign Type :{" "}
                        <span
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#5CAD46",
                            paddingLeft: 5,
                            textTransform: "capitalize",
                          }}
                        >
                          {campaign && campaign.type}
                        </span>
                      </div>
                    </div>
                    <div style={{ marginTop: 32 }}>
                      <Row>
                        <Col span={8}>
                          <div
                            style={{
                              color: "#1B2014",
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            Total Content Generated
                          </div>
                          <div
                            style={{
                              color: "#1B2014",
                              fontSize: "48px",
                              fontWeight: "bold",
                            }}
                          >
                            {campaign &&
                              campaign.contentStats &&
                              campaign.contentStats.totalContent}
                          </div>
                        </Col>
                        <Col
                          span={5}
                          style={{
                            borderRight: "1px solid #DEDEDE",
                            borderLeft: "1px solid #DEDEDE",
                            paddingLeft: 25,
                            height: 69,
                          }}
                        >
                          <div style={{ color: "#000000", fontSize: "16px" }}>
                            Total Users
                          </div>
                          <div
                            style={{
                              color: "#000000",
                              fontSize: "22px",
                              fontWeight: "bold",
                            }}
                          >
                            {campaign &&
                              campaign.contentStats &&
                              campaign.contentStats.totalUsers}
                          </div>
                        </Col>
                        <Col
                          span={5}
                          style={{
                            borderRight: "1px solid #DEDEDE",
                            paddingLeft: 25,
                            height: 69,
                          }}
                        >
                          <div style={{ color: "#000000", fontSize: "16px" }}>
                            Average Rating
                          </div>
                          <div
                            style={{
                              color: "#000000",
                              fontSize: "22px",
                              fontWeight: "bold",
                            }}
                          >
                            {campaign &&
                              campaign.contentStats &&
                              campaign.contentStats.averageRating}
                          </div>
                        </Col>
                        <Col span={5} style={{ paddingLeft: 25, height: 69 }}>
                          <div style={{ color: "#000000", fontSize: "16px" }}>
                            Rejection Rate
                          </div>
                          <div
                            style={{
                              color: "#000000",
                              fontSize: "22px",
                              fontWeight: "bold",
                            }}
                          >
                            {campaign &&
                              campaign.contentStats &&
                              campaign.contentStats.rejectedContent}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>

                <div className="site-card-border-less-wrapper">
                  <Card
                    bordered={false}
                    style={{
                      width: "100%",
                      padding: 30,
                      height: "auto",
                      marginBottom: "30px",
                    }}
                  >
                    <div style={{ fontSize: 18, fontWeight: "bold" }}>
                      Campaign Description
                    </div>
                    <Card
                      style={{
                        margin: "30px 0px",
                        height: "auto",
                        border: "none",
                      }}
                    >
                      <Row>
                      <Col
                          span={16}
                          style={{
                            paddingRight: "30px ",
                            textAlign: "justify",
                          }}
                        >
                          <p style={{ fontSize: 14, fontWeight: 500 }}>Cover Photo</p>
                          <img
                            style={{
                              borderRadius: 15,
                              width: "100%",
                              // height: "186px",
                            }}
                            alt="example"
                            src={
                              campaign
                                ? campaign.coverPhoto
                                : DEFAULT_MEMBER_PROFILE_IMAGE
                            }
                          />
                        </Col>
                        <Col span={8}
                         style={{
                          paddingRight: "30px ",
                          textAlign: "justify",
                        }}>
                           <p style={{ fontSize: 14, fontWeight: 500 }}>Campaign Photo</p>
                          <img
                            style={{
                              borderRadius: 15,
                              marginBottom: 10,
                              // width: "269px",
                              // height: "186px",
                            }}
                            alt="example"
                            src={
                              campaign
                                ? campaign.photo
                                : DEFAULT_MEMBER_PROFILE_IMAGE
                            }
                          />
                        </Col>
                       
                        <Col
                          span={24}
                          style={{
                            paddingTop: "15px ",
                            textAlign: "justify",
                          }}
                        >
                          {campaign && campaign.description}
                        </Col>
                      </Row>
                    </Card>

                    {campaign && campaign.rewards && (
                      <div>
                        <hr
                          style={{
                            border: "1px solid #DEDEDE",
                            margin: "30px 0px",
                          }}
                        />
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: "bold",
                            marginBottom: 30,
                          }}
                        >
                          Campaign Rewards
                        </div>
                        {campaign &&
                          campaign.rewards.map((reward) => {
                            return (
                              <div style={{ marginBottom: 20 }}>
                                <div style={{ fontSize: 14, marginBottom: 6 }}>
                                  {reward.title}
                                </div>
                                <div style={{ fontSize: 16, fontWeight: 600 }}>
                                  {reward.description}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </Card>
                </div>
              </Col>
              <Col span={8}>
                <div
                  className="site-card-border-less-wrapper"
                  style={{ marginLeft: 30, marginBottom: 30 }}
                >
                  <Card
                    bordered={false}
                    style={{ width: "100%", padding: "30px 0px" }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: "bold",
                        padding: "0px 30px",
                      }}
                    >
                      Leaderboard
                    </div>
                    {this.state.leaderBoard.map((item) => {
                      return (
                        <div>
                          <Row
                            //  gutter={{ xs: 8, sm: 16, md: 24, lg: 32,xl:24 }}
                            style={{
                              padding: 30,
                              borderBottom: "1px solid #DEDEDE",
                              alignItems: "center",
                            }}
                          >
                            <Col
                              span={4}
                              style={{ fontSize: 18, fontWeight: "bold" }}
                            >
                              {item.rank === 1 ? (
                                <img src={star} />
                              ) : (
                                "#" + item.rank
                              )}
                            </Col>
                            <Col
                              span={6}
                              style={{ fontSize: 18, fontWeight: "bold" }}
                            >
                              {item.user && item.user.profilePictureURL && (
                                <img
                                  style={{
                                    width: 60,
                                    height: 60,
                                    borderRadius: 35,
                                  }}
                                  src={item.user.profilePictureURL}
                                />
                              )}
                            </Col>
                            <Col
                              span={12}
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                paddingLeft: 10,
                              }}
                            >
                              {item.user && item.user.firstName}{" "}
                              {item.user && item.user.lastName}
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Modal
          // title="Approved"
          centered
          visible={this.state.visibleApproveModal}
          footer={null}
          width={610}
          style={{ padding: 35 }}
          onCancel={() => {
            this.setState({ visibleApproveModal: false });
          }}
        >
          <Row style={{ justifyContent: "center" }}>
            <p style={{ fontSize: 22, fontWeight: "bold", marginBottom: 30 }}>
              Approved
            </p>
          </Row>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p style={{ textAlign: "center" }}>
              Bring Friends, {campaign ? campaign.title : ""} has been approved.
            </p>

            <Button
              style={{ width: 86, marginTop: 25 }}
              type="primary"
              onClick={() => {
                this.navigate("/admin/campaigns");
              }}
            >
              Done
            </Button>
          </div>
        </Modal>
        <Modal
          // title="Are you sure you want to remove this campaign?"
          centered
          visible={this.state.visibleRejectModal}
          footer={null}
          onOk={() => {
            this.setState({ visibleRejectModal: false });
          }}
          onCancel={() => {
            this.setState({ visibleRejectModal: false });
          }}
          width={610}
        >
          <div>
            <Row>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 22,
                  fontWeight: "bold",
                  marginBottom: 30,
                }}
              >
                Are you sure you want to remove this campaign?
              </p>
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
              <Button
                style={{ width: 86, marginRight: 10 }}
                type="primary"
                danger
                onClick={() => this.approve(false)}
              >
                Yes
              </Button>
              <Button
                style={{ width: 86 }}
                type="default"
                onClick={() => this.setState({ visibleRejectModal: false })}
              >
                No
              </Button>
            </Row>
          </div>
        </Modal>
        <Modal
          // title="Are you sure you want to remove this campaign?"
          centered
          visible={this.state.visibleModal}
          footer={null}
          onOk={() => {
            this.setState({ visibleModal: false });
          }}
          onCancel={() => {
            this.setState({ visibleModal: false });
          }}
          width={610}
        >
          <div>
            <Row>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 22,
                  fontWeight: "bold",
                  marginBottom: 30,
                }}
              >
                Are you sure you want to approve this campaign?
              </p>
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
              <Button
                style={{ width: 86, marginRight: 10 }}
                type="primary"
                onClick={() => this.approve(true)}
              >
                Yes
              </Button>
              <Button
                style={{ width: 86 }}
                type="default"
                onClick={() => this.setState({ visibleModal: false })}
              >
                No
              </Button>
            </Row>
          </div>
        </Modal>
        <Modal
          // title="Are you sure you want to remove this campaign?"
          centered
          visible={this.state.visibleConfirmHideModal}
          footer={null}
          onOk={() => {
            this.setState({ visibleConfirmHideModal: false });
          }}
          onCancel={() => {
            this.setState({ visibleConfirmHideModal: false });
          }}
          width={610}
        >
          <div>
            <Row>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 22,
                  fontWeight: "bold",
                  marginBottom: 30,
                }}
              >
                Are you sure you want to{" "}
                {campaign.status === "hidden" ? "unhide" : "hide"} this
                campaign?
              </p>
            </Row>
            <Row style={{ justifyContent: "flex-end" }}>
              <Button
                style={{ width: 86, marginRight: 10 }}
                type="primary"
                onClick={() => this.hide()}
              >
                Yes
              </Button>
              <Button
                style={{ width: 86 }}
                type="default"
                onClick={() =>
                  this.setState({ visibleConfirmHideModal: false })
                }
              >
                No
              </Button>
            </Row>
          </div>
        </Modal>
        <Modal
          // title="Are you sure you want to remove this campaign?"
          centered
          visible={this.state.visiblePickWinnwrModal}
          footer={null}
          onOk={() => {
            this.setState({ visiblePickWinnwrModal: false });
          }}
          onCancel={() => {
            this.setState({ visiblePickWinnwrModal: false });
          }}
          width={610}
        >
          <div>
            <Row>
              <p
                style={{
                  textAlign: "center",
                  fontSize: 22,
                  fontWeight: 600,
                  marginBottom: 30,
                }}
              >
                Pick Winners
              </p>
            </Row>
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={this.getWinners}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row>
                <Col span={24}>
                  <p
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                      fontWeight: "bold",
                      marginBottom: 30,
                    }}
                  >
                    Enter a preffered number of winners
                  </p>

                  <Form.Item name="count">
                    <Input type="number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ justifyContent: "flex-end" }}>
                <Button htmlType="submit" style={{ width: 183 }} type="primary">
                  Pick Winners
                </Button>
              </Row>
            </Form>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
