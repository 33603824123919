import { SearchOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Divider,
} from "antd";
import { Formik } from "formik";
import moment from "moment";
import React from "react";

const AdminModeratorFilter = (props) => {
  const { RangePicker } = DatePicker;
  const { setAddModal, onSubmit, setEditModal, moderators } = props;

  const moderatorFilter = {
    query: "",
    startDate: null,
    endDate: null,
  };

  return (
    <div className="subheader">
      <Row className="container">
        <Col span={4}>
          <p className="page-header">Moderators</p>
        </Col>
        <Col span={20}>
          <Formik
            initialValues={moderatorFilter}
            onSubmit={(values, actions) => {
              console.log({ values, actions });
              onSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              handleReset,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmitCapture={handleSubmit} colon={false}>
                <Row style={{ float: "right" }}>
                  {/* <Form.Item className="inline-form-item">
                    <Input
                      style={{ width: "250px" }}
                      placeholder="Search"
                      name="query"
                      value={values.query}
                      onChange={handleChange}
                      className="rounded"
                      prefix={<SearchOutlined />}
                    />
                  </Form.Item>

                  <Form.Item className="inline-form-item" label="Date">
                    <RangePicker
                      dropdownClassName="rounded"
                      className="rounded"
                      ranges={{
                        Today: [moment(), moment()],
                        Yesterday: [
                          moment().subtract(1, "day"),
                          moment().subtract(1, "day"),
                        ],
                        "This Week": [
                          moment().startOf("week"),
                          moment().endOf("week"),
                        ],
                        "Last Week": [
                          moment().subtract(1, "week").startOf("week"),
                          moment().subtract(1, "week").endOf("week"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                      onChange={(e) => {
                        if (e) {
                          let startDate = moment(e[0]).format("YYYY/MM/DD");
                          let endDate = moment(e[1]).format("YYYY/MM/DD");
                          console.log(startDate, endDate);
                          setFieldValue("startDate", startDate);
                          setFieldValue("endDate", endDate);
                        } else {
                          setFieldValue("startDate", null);
                          setFieldValue("endDate", null);
                        }
                      }}
                    />
                  </Form.Item>

                  <Button
                    className="inline-form-item"
                    htmlType="submit"
                    type="primary"
                  >
                    Apply
                  </Button> */}
                  {moderators.length > 0 ? (
                    <Button
                      onClick={() => setEditModal(true)}
                      type="default"
                      className="inline-form-item"
                    >
                      Edit
                    </Button>
                  ) : null}

                  <Button
                    onClick={() => setAddModal(true)}
                    className="inline-form-item"
                    type={moderators.length > 0 ? "primary" : "default"}
                  >
                    + Add Moderators
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default AdminModeratorFilter;
