import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { Select, Row, Col, Input, Button, Form, DatePicker, Tag } from "antd";
import { Formik } from "formik";
import React, { useContext } from "react";
import moment from "moment";
import { PartnerContext } from "../../../context/partnerContext";
import { useHistory } from "react-router-dom";

const Filters = ({showModal, onSubmit, isAdmin, selectedPartner, filters, rank }) => {
  const history = useHistory();

  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [partners, setPartners] = useContext(PartnerContext);
  const filterValues = {
    query: filters.filter && filters.filter.query ? filters.filter.query : null,
    partner:
      filters.filter && filters.filter.partner ? filters.filter.partner : null,
    startDate:
      filters.filter && filters.filter.startDate
        ? filters.filter.startDate
        : null,
    endDate:
      filters.filter && filters.filter.endDate ? filters.filter.endDate : null,
    status:
      filters.filter && filters.filter.status ? filters.filter.status : null,
    country:
      filters.filter && filters.filter.status ? filters.filter.status : null,
  };

  const navigate = (path) => {
    history.push(path);
  };
  return (
    <div className="subheader">
      <Formik
        initialValues={filterValues}
        onSubmit={(values, actions) => {
          console.log(values);
          onSubmit(values);
          actions.setSubmitting(true);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmitCapture={handleSubmit} colon={false}>
            <Row
              className="container"
              style={{ display: "flex", justifyContent: "space-between", paddingBottom:10}}
            >
              <Col span={4}>
                <h3>Billing</h3>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  onClick={() => {
                    showModal(true);
                  }}
                >
                  Send Refund
                </Button>
              </Col>
            </Row>

            <Row className="container">
              <Col span={23}>
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Col span={6}>
                    <Form.Item className="inline-form-item">
                      <Input
                        value={values.query}
                        placeholder="Search"
                        name="query"
                        className="rounded"
                        prefix={<SearchOutlined />}
                        onChange={handleChange}
                      />{" "}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item className="inline-form-item" label="Country">
                      <Select
                        defaultValue={null}
                        value={values.country}
                        id="Country"
                        showSearch
                        onChange={(e) => {
                          setFieldValue("country", e);
                        }}
                      >
                        <Option value={null}>All</Option>
                        <Option value={'LKR'}>Sri Lanka</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label="Date" className="inline-form-item">
                      <DatePicker
                        defaultValue={moment(new Date(), "YYYY-MMM")}
                        format={"YYYY-MMM"}
                        picker="month"
                        
                        onChange={(e) => {
                          if (e) {
                            let startDate = moment(e[0]).format("YYYY/MM");
                            let endDate = moment(e[1]).format("YYYY/MM");
                            setFieldValue("startDate", startDate);
                            setFieldValue("endDate", endDate);
                          } else {
                            setFieldValue("startDate", null);
                            setFieldValue("endDate", null);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item className="inline-form-item" label="Status">
                      <Select
                        defaultValue={null}
                        value={values.status}
                        id="status"
                        showSearch
                        onChange={(e) => {
                          setFieldValue("status", e);
                        }}
                      >
                        <Option value={null}>All</Option>
                        <Option value="success">Paid</Option>
                        <Option value="pending">Pending</Option>
                        <Option value="refund">Refund</Option>
                        
                      </Select>
                    </Form.Item>
                    </Col>
                  <Col span={1}>
                    <Button
                          htmlType="submit"
                          type="default"
                          style={{ marginRight: 10 }}
                        >
                          Apply
                        </Button>
                        
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <hr style={{ color: "#DEDEDE", opacity: 0.2, marginTop: 30 }} />
              </Col>
              <Col span={6}>
                <div>
                  <h6 style={{ color: "#1B2014", opacity: 0.6, marginTop: 12 }}>
                    {rank} Partners
                  </h6>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Filters;
