import React, { Component } from "react";
import {
  Col,
  Pagination,
  Row,
  Skeleton,
  Table,
  Tag,
  Avatar,
  message,
} from "antd";

import Filters from "./partials/Filters";
// Data
import AvocadoAdmin from "../../api/avocado.admin";
import { campaignFilterToParams, processCampaignFilters } from "./common";
import CampaignCard from "../../components/CampaignCard";
import PartnerBills from "../../components/partner-bill";
const DEFAULT_MEMBER_PROFILE_IMAGE =
  "https://avacado-partner-bucket-dev.s3-ap-southeast-1.amazonaws.com/placeholder_images/avocado-avatar.jpg";

export default class Bills extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      paging: {
        totalDocs: 0,
      },
      // leaderboard: [],
      partner: "all",
      filters: {},
      campaigns: [],
    };

    this.isAdmin = this.isAdmin.bind(this);
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin/campaigns";
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <PartnerBills
          brandId={1}
            isAdmin={false}
          ></PartnerBills>
        </div>
      </React.Fragment>
    );
  }
}
