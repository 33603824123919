import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import { Formik } from "formik";
import React from "react";

const ChangePassword = ({ isVisible, setChangePassword, onChangePassword }) => {
  return (
    <React.Fragment>
      <Modal
        visible={isVisible}
        style={{ borderRadius: "12px" }}
        title="Change Password"
        footer={null}
        onCancel={() => setChangePassword(false)}
      >
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          onSubmit={async (values, actions) => {
            console.log(values);
            actions.setSubmitting(true);
            if (values.confirmPassword !== values.newPassword) {
              message.error(
                "Your New Password and Confirm Password doesnt match"
              );
            } else {
              onChangePassword(values);
            }
            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
            setSubmitting,
            isSubmitting,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="justify-center">
              <Form
                onSubmitCapture={handleSubmit}
                className="inner-row"
                layout="vertical"
                colon={false}
              >
                <Form.Item label="Current Password">
                  <Input
                    name="currentPassword"
                    onChange={handleChange}
                    type="password"
                  />
                </Form.Item>
                <Form.Item label="New Password">
                  <Input
                    name="newPassword"
                    onChange={handleChange}
                    type="password"
                  />
                </Form.Item>
                <Form.Item label="Confirm Password">
                  <Input
                    name="confirmPassword"
                    onChange={handleChange}
                    type="password"
                  />
                </Form.Item>
                <div style={{ textAlign: "right" }}>
                  <Button
                    loading={isSubmitting}
                    key="submit"
                    htmlType="submit"
                    type="default"
                  >
                    Change Password
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default ChangePassword;
