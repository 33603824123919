import React, { Component } from "react";
import {
  Col,
  Pagination,
  Row,
  Skeleton,
  Table,
  Tag,
  Avatar,
  message,
} from "antd";

import Filters from "./partials/Filters";
// Data
import AvocadoAdmin from "../../api/avocado.admin";
import { campaignFilterToParams, processCampaignFilters } from "./common";
import CampaignCard from "../../components/CampaignCard";
const DEFAULT_MEMBER_PROFILE_IMAGE =
  "https://avacado-partner-bucket-dev.s3-ap-southeast-1.amazonaws.com/placeholder_images/avocado-avatar.jpg";

export default class Campaign extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      paging: {
        totalDocs: 0,
      },
      // leaderboard: [],
      partner: "all",
      filters: {},
      campaigns: [],
    };

    this.isAdmin = this.isAdmin.bind(this);

    this.getCampaign = this.getCampaign.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  componentDidMount() {
    window.document.title = "Avocado - Campaigns";
    const filters = processCampaignFilters(
      new URLSearchParams(this.props.location.search)
    );

    this.getCampaign(filters.filter ? filters.filter : {});
    this.getCampaign();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      const filters = processCampaignFilters(
        new URLSearchParams(this.props.location.search)
      );

      this.getCampaign(filters.filter ? filters.filter : {});
    }
  }

  isAdmin(): boolean {
    return this.props.match.path === "/admin/campaigns";
  }

  async onSubmit(filter) {
    this.setState({
      partner: filter.partner,
      filters: filter,
    });
    let data = campaignFilterToParams(filter);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
  }

  async getCampaign(filters: any = {}) {
    this.setState({ isLoading: true });

    const user = JSON.parse(localStorage.getItem("avocado-user"));

    let params = { ...filters };

    if (this.isAdmin()) {
      if (filters["partner"]) params["brandId"] = filters["partner"];
    } else {
      params["brandId"] = user.permissions.scope;
    }

    if (filters.partner === "all" && this.isAdmin()) {
      delete params.brandId;
    }
    console.log(params.endDate);

    try {
      // message.loading("Loading...");
      let result = await AvocadoAdmin.getAllCampaigns(params);
      this.setState({ campaigns: result.body, paging: result.paging });
    } catch (error) {}
    this.setState({ isLoading: false });
  }

  async changePage(pageNumber, limit) {
    const filters = { ...this.state.filters, page: pageNumber, limit };
    let data = campaignFilterToParams(filters);
    console.log(data);
    this.props.history.push(`${this.props.match.path}?${data}`);
    // this.getCampaign(filters);
  }

  render() {
    const { campaigns } = this.state;
    const filters = processCampaignFilters(
      new URLSearchParams(this.props.location.search)
    );

    return (
      <React.Fragment>
        <div className="filter">
          <Filters
            filters={filters}
            onSubmit={this.onSubmit}
            isAdmin={this.isAdmin()}
            selectedPartner={this.state.partner}
            rank={this.state.paging ? this.state.paging.totalDocs : 0}
          />
        </div>
        <div className="container">
          <Row>
            <Col span={24}>
              {campaigns.map((campaign) => {
                  return <CampaignCard campaign={campaign}></CampaignCard>;
                
              })}
            </Col>
          </Row>
          <Row className="container">
            <Col span={24}>
              {this.state.isLoading ? (
                <Skeleton active></Skeleton>
              ) : (
                <React.Fragment>
                  <Pagination
                    className="text-center"
                    style={{ padding: "2rem 0rem" }}
                    total={this.state.paging.totalDocs}
                    onChange={(pageNumber, limit) => {
                      this.changePage(pageNumber, limit);
                    }}
                    pageSizeOptions={["10", "15", "30"]}
                    pageSize={
                      this.state.paging.limit ? this.state.paging.limit : 10
                    }
                    current={
                      this.state.paging.page ? this.state.paging.page : 1
                    }
                    showSizeChanger
                  ></Pagination>
                </React.Fragment>
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
