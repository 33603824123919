import React, { useState, useEffect, useRef } from "react";

let autoComplete;

const parsePlace = (raw) => {
  const country = raw.address_components.filter((component) => {
    return component.types.indexOf("country") >= 0;
  });

  const admin1 = raw.address_components.filter((component) => {
    return component.types.indexOf("administrative_area_level_1") >= 0;
  });

  const admin2 = raw.address_components.filter((component) => {
    return component.types.indexOf("administrative_area_level_2") >= 0;
  });

  const locality = raw.address_components.filter((component) => {
    return component.types.indexOf("locality") >= 0;
  });

  const location_lat = raw.geometry.location.lat();
  const location_lng = raw.geometry.location.lng();

  return {
    formatted_address: raw.formatted_address,
    country: country && country.length > 0 ? country[0].long_name : null,
    admin_1: admin1 && admin1.length > 0 ? admin1[0].long_name : null,
    admin_2: admin2 && admin2.length > 0 ? admin2[0].long_name : null,
    locality: locality && locality.length > 0 ? locality[0].long_name : null,
    location_lat: location_lat ? location_lat : null,
    location_lng: location_lng ? location_lng : null,
  };
};

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, updateLocation, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["establishment"] }
  );
  autoComplete.setFields([
    "address_components",
    "formatted_address",
    "geometry",
  ]);
  autoComplete.addListener("place_changed", () => {
    handlePlaceSelect(updateQuery, updateLocation);
  });
}

async function handlePlaceSelect(updateQuery, updateLocation) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  updateLocation(parsePlace(addressObject));
}

function LocationSearchInput({ value, onLocationUpdated }) {
  const [query, setQuery] = useState(value);
  const [readOnly, setReadOnly] = useState(true);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyDmKojsIIANqks5y5x-7pQG3MA2WcufG1Y"}&libraries=places`,
      () =>
        handleScriptLoad(
          setQuery,
          (selectedLocation) => {
            onLocationUpdated(selectedLocation);
          },
          autoCompleteRef
        )
    );
  }, []);

  return (
    <div className="search-location-input">
      <input
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        onFocus={() => {
          setReadOnly(false);
          setQuery("");
        }}
        onBlur={() => {
          setReadOnly(true);
        }}
        type="text"
        placeholder="Choose a City"
        value={query}
        className="ant-input ant-input"
        readOnly={readOnly}
        autoComplete="Off"
      />
    </div>
  );
}

export default LocationSearchInput;
